import React, { useState } from 'react'
import { Row, Input, Label, Col, Button, Form } from 'reactstrap'
import updateIcon from '../images/update.png'
import { login, resetPassword } from '../firebase'
import { useSiteContext } from '../context/SiteContext'
import db, { storage } from '../firebase'
import { useTranslation } from 'react-i18next'
import { MdCloudUpload, MdDelete } from 'react-icons/md'
import Loader from './Loader';
import toast from 'react-hot-toast'
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage'


const Profile = () => {
    const { loginInfo, setPattern } = useSiteContext();

    setPattern(undefined)


    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [imageAsset, setImageAsset] = useState(null);
    const [logoUrl_, setLogoUrl_] = useState();
    const [msg, setMsg] = useState(null);
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [productImgURL, setProductImgURL] = useState()



    const uploadLogo = () => {
        db.collection("profile/").doc("profile_" + JSON.parse(localStorage.getItem("user"))?.uid).update(
            {
                logoURL: logoUrl_
            }
        )
    }

    const deleteLogo = () => {
        deleteObject(ref(storage, loginInfo && loginInfo[0].logoURL));
        db.collection("profile/").doc("profile_" + JSON.parse(localStorage.getItem("user"))?.uid).update(
            {
                logoURL: ""
            }
        )

    }

    const uploadImage = (e) => {
        setIsLoading(true);
        const imageFile = e.target.files[0];
        const storageRef = ref(storage, `logo/${JSON.parse(localStorage.getItem("user"))?.uid}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on('state_changed', (snapshot) => {
            const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, (error) => {
            console.log(error);
            setFields(true);
            setMsg('Error while uploading : Try Again🙇‍♂️');
            setAlertStatus('danger');
            setTimeout(() => {
                setFields(false);
                setIsLoading(false);
            }, 4000)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                setImageAsset(downloadURL);
                setProductImgURL(downloadURL);
                console.log(downloadURL)
                setIsLoading(false);
                setFields(true);
                setLogoUrl_(downloadURL)
                toast.success('Image uploaded succesfully😎');
                setAlertStatus("success");
                setTimeout(() => {
                    setFields(false);
                }, 4000);

            })
        });
    }

    const deleteImage = () => {
        setIsLoading(true);
        const deleteRef = ref(storage, imageAsset);
        deleteObject(deleteRef).then(() => {
            setImageAsset(null);
            setIsLoading(false);
            setFields(true);
            setMsg('Image deleted succesfully🚮🗑');
            setTimeout(() => {
                setFields(false);
            }, 4000);
        })
    }






    const [visiblePassword, setVisiblePassword] = useState(false)

    const visiblePasswordHandle = () => {
        setVisiblePassword(visiblePassword === false ? true : false)
    }

    const [visibleEmail, setVisibleEmail] = useState(false)

    const visibleEmailHandle = () => {
        setVisibleEmail(visibleEmail === false ? true : false)
    }

    const [visiblePhone, setVisiblePhone] = useState(false)

    const visiblePhoneHandle = () => {
        setVisiblePhone(visiblePhone === false ? true : false)
    }

    const [visibleAddress, setVisibleAddress] = useState(false)

    const visibleAddressHandle = () => {
        setVisibleAddress(visibleAddress === false ? true : false)
    }

    const [password, setPassword] = useState('')

    const updatePassword = async e => {
        e.preventDefault()
        const result = await resetPassword(password)
        if (result) {
            setPassword('')
        }
    }

    const [email, setEmail] = useState('')

    const updateEmail = () => { }

    const [phone, setPhone] = useState('')

    const updatePhone = async e => {
        e.preventDefault()
        db.collection("profile/").doc("profile_" + JSON.parse(localStorage.getItem("user"))?.uid).update({
            phone: phone.toString(),
        })
    }

    const updateAddress = async e => {
        e.preventDefault()
        db.collection("profile/").doc("profile_" + JSON.parse(localStorage.getItem("user"))?.uid).update({
            address: address.toString(),
        })
    }



    const [address, setAddress] = useState('')

    // const checkEmail = () => {
    //     user.email === user1Mail
    // }

    let user1Mail = "yigit@deneme.com"
    let user1Pass = "123"

    // console.log(  
    //     db.collection("profile/profile/profile").where("email","==","mert").onSnapshot(snapshot => { 
    //     console.log(snapshot)
    //    return setLoginInfo(snapshot.docs.map(doc => (
    //     {
    //     id: doc.data().id,
    //     address: doc.data().address,
    //     logoURL: doc.data().logoURL,
    //     email: doc.data().email,
    //     phone: doc.data().phone,
    //     period: doc.data().period,
    //     periodStart: doc.data().periodStart,
    //     periodEnd: doc.data().periodEnd,
    //     userLevel: doc.data().userLevel
    //   }

    //   )))})

    //   )

    return (
        <>
            <Row>
                <h4>Logo</h4>

            </Row>
            {loginInfo && loginInfo.map((user, index) => <div className="hide-mobile">
                {loginInfo && loginInfo[0].logoURL === "" ? <div> {isLoading ? <Loader /> : (<>
                    {!imageAsset ? (<>
                        <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
                            <div className='w-full h-full flex flex-col items-center justify-center cursor-pointer gap-2'>
                                <MdCloudUpload className='text-gray-500 text-3xl hover:text-gray-700' />
                                <p className='text-gray-500 text-xl'> {t('description.uploadImage')} </p>
                            </div>
                            <input type="file" name="uploadimage" accept='image/*' onChange={uploadImage} className='w-0 h-0 ' />
                        </label>
                    </>) :
                        (<> <div className='relative h-20 '>
                            <img src={imageAsset} alt="uploaded image" style={{ width: 200 }} />
                            <button type='button' className='absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all' onClick={deleteImage}>
                                <MdDelete className='text-white' />
                            </button>
                        </div>
                        </>)}
                </>)}
                    <Button onClick={uploadLogo} > {t('description.saveLogo')} </Button></div> : <div><img src={loginInfo && loginInfo[0].logoURL} width="250" /> <Button onClick={deleteLogo}> {t('description.deleteLogo')} </Button></div>}

                <Row style={{ justifyContent: 'center' }}>
                    <h4>{t('description.userSettings')}</h4>

                </Row>
                <Row>
                    <Col xl="10" lg="12" className="profile-card w-85" >
                        <Row>
                            <Col xl="6">
                                {t('description.periodStartDate')}: {user.periodStart}
                            </Col>
                            <Col xl="6">
                                {t('description.periodEndDate')}: {user.periodEnd}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xl="5" className="profile-card">
                        <Row>
                            <Col xl="12">
                                <Label><b>{t('description.email')}:</b> {user.email} </Label>
                                {/* <img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visibleEmailHandle} /> */}
                            </Col>
                        </Row>
                        <Row>
                            {visibleEmail ?
                                <Col>
                                    <Form onSubmit={updateEmail} className="d-flex">
                                        <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <Button type="submit" disabled={!email}>{t('description.update')}</Button>
                                    </Form>
                                </Col> : <Col />}
                        </Row>
                    </Col>
                    <Col xl="5" className="profile-card">
                        <Row>
                            <Col xl="12">
                                <Label>
                                    <b>{t('description.password')}:</b> ******</Label><img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visiblePasswordHandle} />
                            </Col>
                        </Row>
                        <Row>
                            {visiblePassword ? <Col>
                                <Form onSubmit={updatePassword} className="d-flex">
                                    <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                                    <Button type="submit" disabled={!password}>{t('description.update')}</Button>
                                </Form>
                            </Col> : <Col />}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xl="5" className="profile-card">
                        <Row>
                            <Col xl="12">
                                <Label><b>{t('description.phone')}:</b>  {user.phone} </Label>
                                <img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visiblePhoneHandle} />
                            </Col>
                        </Row>
                        <Row>
                            {visiblePhone ?
                                <Col>
                                    <Form onSubmit={updatePhone} className="d-flex">
                                        <Input type="tel" value={phone} onChange={e => setPhone(e.target.value)} />
                                        <Button type="submit" disabled={!phone}>{t('description.update')}</Button>
                                    </Form>
                                </Col> : <Col />}
                        </Row>
                    </Col>
                    <Col xl="5" className="profile-card">
                        <Row>
                            <Col xl="12">
                                <Label>
                                    <b>{t('description.address')}:</b> {user.address} </Label><img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visibleAddressHandle} />
                            </Col>
                        </Row>
                        <Row>
                            {visibleAddress ? <Col>
                                <Form onSubmit={updateAddress} className="d-flex">
                                    <Input type="text" value={address} onChange={e => setAddress(e.target.value)} />
                                    <Button type="submit" disabled={!address}>{t('description.update')}</Button>
                                </Form>
                            </Col> : <Col />}
                        </Row>
                    </Col>
                </Row>


            </div>)}

            {loginInfo && loginInfo.map((user, index) => <div className="hide-desktop">
                {loginInfo && loginInfo[0].logoURL === "" ? <div> {isLoading ? <Loader /> : (<>
                    {!imageAsset ? (<>
                        <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
                            <div className='w-full h-full flex flex-col items-center justify-center cursor-pointer gap-2'>
                                <MdCloudUpload className='text-gray-500 text-3xl hover:text-gray-700' />
                                <p className='text-gray-500 text-xl'> {t('description.uploadImage')} </p>
                            </div>
                            <input type="file" name="uploadimage" accept='image/*' onChange={uploadImage} className='w-0 h-0 ' />
                        </label>
                    </>) :
                        (<> <div className='relative h-20 '>
                            <img src={imageAsset} alt="uploaded image" style={{ width: 200 }} />
                            <button type='button' className='absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all' onClick={deleteImage}>
                                <MdDelete className='text-white' />
                            </button>
                        </div>
                        </>)}
                </>)}
                    <Button onClick={uploadLogo} > {t('description.saveLogo')} </Button></div> : <div><img src={loginInfo && loginInfo[0].logoURL} width="250" /> <Button onClick={deleteLogo}> {t('description.deleteLogo')} </Button></div>}

                <Row style={{ justifyContent: 'center' }}>
                    <h4>{t('description.userSettings')}</h4>

                </Row>
                <Row>
                    <Col xl="10" lg="12" className="profile-card w-85" >
                        <Row>
                            <Col xl="4">
                                {t('description.periodStartDate')}: {user.periodStart}
                            </Col>
                            <Col xl="4">
                                {t('description.periodEndDate')}: {user.periodEnd}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xl="5" className="profile-card w-85">
                        <Row>
                            <Col xl="12">
                                <Label><b>{t('description.email')}:</b> {user.email} </Label>
                                {/* <img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visibleEmailHandle} /> */}
                            </Col>
                        </Row>
                        <Row>
                            {visibleEmail ?
                                <Col>
                                    <Form onSubmit={updateEmail} className="d-flex">
                                        <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                        <Button type="submit" disabled={!email}>{t('description.update')}</Button>
                                    </Form>
                                </Col> : <Col />}
                        </Row>
                    </Col>
                    <Col xl="5" className="profile-card w-85">
                        <Row>
                            <Col xl="12">
                                <Label>
                                    <b>{t('description.password')}:</b> ******</Label><img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visiblePasswordHandle} />
                            </Col>
                        </Row>
                        <Row>
                            {visiblePassword ? <Col>
                                <Form onSubmit={updatePassword} className="d-flex">
                                    <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                                    <Button type="submit" disabled={!password}>{t('description.update')}</Button>
                                </Form>
                            </Col> : <Col />}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xl="5" className="profile-card w-85">
                        <Row>
                            <Col xl="12">
                                <Label><b>{t('description.phone')}:</b>  {user.phone} </Label>
                                <img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visiblePhoneHandle} />
                            </Col>
                        </Row>
                        <Row>
                            {visiblePhone ?
                                <Col>
                                    <Form onSubmit={updatePhone} className="d-flex">
                                        <Input type="tel" value={phone} onChange={e => setPhone(e.target.value)} />
                                        <Button type="submit" disabled={!phone}>{t('description.update')}</Button>
                                    </Form>
                                </Col> : <Col />}
                        </Row>
                    </Col>
                    <Col xl="5" className="profile-card w-85">
                        <Row>
                            <Col xl="12">
                                <Label>
                                    <b>{t('description.address')}:</b> {user.address} </Label><img src={updateIcon} alt="Update Item" width="24" className="mx-3" onClick={visibleAddressHandle} />
                            </Col>
                        </Row>
                        <Row>
                            {visibleAddress ? <Col>
                                <Form onSubmit={updateAddress} className="d-flex">
                                    <Input type="text" value={address} onChange={e => setAddress(e.target.value)} />
                                    <Button type="submit" disabled={!address}>{t('description.update')}</Button>
                                </Form>
                            </Col> : <Col />}
                        </Row>
                    </Col>
                </Row>


            </div>)}


        </>


    )
}

export default Profile