import React, { useState, useContext, useEffect } from 'react'
import { useSiteContext } from '../context/SiteContext'
import { Col, Form, Input, Label, Row, InputGroup, FormGroup, Button, FormText } from 'reactstrap'
import Switch from 'react-switch';
import toast from 'react-hot-toast'
import db, { storage, ref, deleteObject, auth } from '../firebase'
import { useTranslation } from 'react-i18next'
import { MdOutlineModeEditOutline } from 'react-icons/md'

const Product = ({ id, name, imgURL, index, category, trName }) => {

    const [i_weight, setI_weight] = useState()
    const [i_price, setI_price] = useState()
    const [i_newPrice, setI_newPrice] = useState()
    const [i_updateTitle, setI_updateTitle] = useState()
    const [i_updateTrTitle, setI_updateTrTitle] = useState()
    const [i_updateCategory, setI_updateCategory] = useState()
    const [i_pcs, setI_pcs] = useState()
    const [i_unit, setI_unit] = useState("0")
    const [i_origin, setI_Origin] = useState()
    const [swCheck, setCheck] = useState(false);
    const [tph, setTph] = useState(false)
    const [tph_, setTph_] = useState(false)

    const { t, i18n } = useTranslation();
    const { loginInfo, setProductIds, newProductIds, setNewProductIds, cartUrunler_, productIds, setCartUrunler_, findKey, setFindKey, setFindKeyArray, findKeyArray, categoryInfo } = useSiteContext();

    const radioHandle = () => {
        setCheck(swCheck === true ? false : true)
    }
    // newPrice -> ilk fiyat |  price -> ikinci fiyat
    const addHandle = () => {
        if (parseFloat(i_price) < parseFloat(i_newPrice)) {
            toast.error(`${t('description.priceNewPrice')}`);
            return;
        } else if (i_newPrice === null || i_newPrice === undefined) {
            toast.error(`${t('description.priceEmpty')}`);
            return;
        } else if (i_weight === null || i_weight === undefined) {
            toast.error(`${t('description.weightEmpty')}`);
            return;
        }
        else if (swCheck === true && i_price === undefined || swCheck === true && i_price === null) {
            toast.error(`${t('description.oldPriceEmpty')}`);
            return;
        } else if (i_unit === "0") {
            toast.error(`${t('description.unitEmpty')}`);
            return;
        } else {
            setNewProductIds(productIds => [...productIds, { id: id, title: name, newPrice: i_newPrice, unit: i_unit, weight: i_weight, price: i_price, pcs: i_pcs, origin: i_origin, imgURL: imgURL, titleTR: trName }]);
            setProductIds(newProductIds);
            localStorage.setItem("productIds", JSON.stringify(newProductIds));
            toggleHandle2()
            toast.success(name + ` ${t('description.productAdded')}`);
            console.log(productIds + " ürün eklendi ballı böreğim")
        }
    }




    const removeItemFromDB = () => {
        setTph_(current => !current)
        console.log(imgURL)
    }
    const removeItemFromDB_ = () => {
        db.collection("product").doc(id).delete();
        deleteObject(ref(storage, imgURL));
        setTph_(current => !current)
    }
    const toggleHandle1 = () => {
        setTph(current => !current)
    }
    const toggleHandle2 = () => {
        setTph(current => !current)
    }
    const toggleHandle3 = () => {
        setTph(current => !current)
    }

    let i = 0;

    const toggleHandle = () => {
        if (cartUrunler_.length != 0) {
            cartUrunler_.filter(key => {
                return key.id === id
            }).map(key => {
                i = 1;
            })
            if (i === 1) {
                toast.error(name + ` ${t('description.alreadyHave')}`)
            } else {
                setTph(current => !current)

            }
        } else {
            setTph(current => !current)
        }
    }

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }

    const updateHandle = () => {
        db.collection("product").doc(id).update({
            name: i_updateTitle ? i_updateTitle : name,
            trName: i_updateTrTitle ? i_updateTrTitle : trName,
            category: i_updateCategory ? i_updateCategory : category
        })
        setTph_(current => !current)
    }



    return (
        <Col xl="3" lg="4" md="4" sm="5" className="product-card" key={index}>
            <Row>
                <Col className="product-image">
                    <img src={imgURL} alt="" height="160px" />
                </Col>
                {loginInfo && loginInfo[0].userLevel === "0" ? <Col color='primary' className="delete-product-button" onClick={removeItemFromDB} ><MdOutlineModeEditOutline /></Col> : null}
                {tph_ && <div className="popup-box">
                    <div className="box">
                        <span className="close-icon" onClick={toggleHandle_}>x</span>
                        <Row className="product-name justify-content-center"> {name} {t('description.updateProduct')} </Row>
                        <Row>
                            <Label>{t('description.name')}</Label><br />
                            <Input placeholder={name} type='text' id='new-name' name='new-name' value={i_updateTitle} onChange={(e) => setI_updateTitle(e.target.value)} />
                        </Row><br />
                        <Row>
                            <Label>{t('description.name')} TR</Label><br />
                            <Input placeholder={trName} type='text' id='new-name' name='new-name' value={i_updateTrTitle} onChange={(e) => setI_updateTrTitle(e.target.value)} />
                        </Row><br />
                        <Row>
                            <Input
                                id="exampleSelect"
                                name="select"
                                type="select"
                                value={i_updateCategory}
                                onChange={(e) => setI_updateCategory(e.target.value)}
                                style={{ marginBottom: 20 }}
                            >
                                <option value={"0"}>
                                    {t('description.selectCategory')}
                                </option>
                                {categoryInfo && categoryInfo.filter((category) => category.id != "all").map(category => (
                                    <option value={category.id}>
                                        {t('description.' + category.id)}
                                    </option>
                                ))}
                            </Input>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={removeItemFromDB_}
                                >
                                    {t('description.delete')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    color="success"
                                    outline
                                    className="float-end"
                                    onClick={updateHandle}
                                >
                                    {t('description.updateProduct')}
                                </Button>
                            </Col>
                        </Row>

                    </div>
                </div>}
                <Col color='primary' className="add-product-button" onClick={toggleHandle} >+</Col>
            </Row>
            <Row className="product-name"> {name} </Row>
            <Row className="product-name-tr"> {trName} </Row>
            {tph && <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={toggleHandle1}>x</span>
                    <Form>
                        <Row>
                            <Row><Label style={{ fontSize: 25 }}>  {name} </Label></Row>
                            <Col>
                                {swCheck ? <Label>{t('description.discountedPrice')}  </Label> : <Label>{t('description.price')} </Label>}
                                <Input placeholder={t('description.price')} type='number' id='new-price' name='new-price' value={i_newPrice} onChange={(e) => setI_newPrice(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            {swCheck ?
                                <Col>
                                    <Label> {t('description.oldPrice')}</Label>
                                    <Input placeholder={t('description.oldPrice')} type='number' id='price' name='price' value={i_price} onChange={(e) => setI_price(e.target.value)} />
                                </Col> : <Col />}
                        </Row>
                        <Row className="mt-2">
                            <Col xl="1" className=" d-flex align-items-center">
                                <Label> {t('description.sale')}</Label>
                            </Col>
                            <Col xl="11">
                                <Switch
                                    onChange={radioHandle}
                                    checked={swCheck}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="switch-price mx-3"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    {t('description.weight')}
                                </Label>
                                <InputGroup>
                                    <Input placeholder={t('description.weight')} type='number' value={i_weight} onChange={(e) => setI_weight(e.target.value)} />
                                    {category === "drinks" ? <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={i_unit}
                                        onChange={(e) => setI_unit(e.target.value)}
                                    >
                                        <option value={"0"}>
                                            {t('description.selectUnit')}
                                        </option>
                                        <option value={"lt"}>
                                            lt.
                                        </option>
                                        <option value={"ml"}>
                                            ml.
                                        </option >
                                    </Input> : <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={i_unit}
                                        onChange={(e) => setI_unit(e.target.value)}
                                    >
                                        <option value={"0"}>
                                            {t('description.selectUnit')}
                                        </option>
                                        <option value={"kg"}>
                                            kg.
                                        </option>
                                        <option value={"gr"}>
                                            gr.
                                        </option >
                                    </Input>}
                                    {/* {category === "vegetable" ? <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={i_unit}
                                        onChange={(e) => setI_unit(e.target.value)}
                                    >
                                        <option value={"0"}>
                                            {t('description.selectUnit')}
                                        </option>
                                        <option value={"kg"}>
                                            kg.
                                        </option>
                                        <option value={"gr"}>
                                            gr.
                                        </option >
                                    </Input> : null}
                                    {category === "fruit" ? <><Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={i_unit}
                                        onChange={(e) => setI_unit(e.target.value)}
                                    >
                                        <option value={"0"}>
                                            {t('description.selectUnit')}
                                        </option>
                                        <option value={"kg"}>
                                            kg.
                                        </option>
                                        <option value={"gr"}>
                                            gr.
                                        </option >
                                    </Input> </> : null}
                                    {category === "drinks" ? <><Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={i_unit}
                                        onChange={(e) => setI_unit(e.target.value)}
                                    >
                                        <option value={"0"}>
                                            {t('description.selectUnit')}
                                        </option>
                                        <option value={"lt"}>
                                            lt.
                                        </option>
                                        <option value={"ml"}>
                                            ml.
                                        </option >
                                    </Input> </> : null}
                                    {category === "butcher" ? <><Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={i_unit}
                                        onChange={(e) => setI_unit(e.target.value)}
                                    >
                                        <option value={"0"}>
                                            {t('description.selectUnit')}
                                        </option>
                                        <option value={"kg"}>
                                            kg.
                                        </option>
                                        <option value={"gr"}>
                                            gr.
                                        </option >
                                    </Input> </> : null} */}
                                    {/* <Input
                                    id="exampleSelect"
                                    name="select"
                                    type="select"
                                    value={i_unit}
                                    onChange={(e) => setI_unit(e.target.value)}
                                >
                                    <option value={"kg"}>
                                        kg.
                                    </option>
                                    <option value={"gr"}>
                                        gr.
                                    </option >
                                    <option value={"lt"}>
                                        lt.
                                    </option>
                                    <option value={"ml"}>
                                        ml.
                                    </option>
                                </Input> */}
                                </InputGroup>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    {t('description.piece')}
                                </Label>
                                <Input placeholder={t('description.piece')} type='number' value={i_pcs} onChange={(e) => setI_pcs(e.target.value)} />
                            </FormGroup>
                            {/* {category === "meatChikenFish" || category === "fruit" ? <FormGroup>
                                <Label for="exampleSelect">
                                    {t('description.piece')}
                                </Label>
                                <Input placeholder={t('description.piece')} type='number' value={i_pcs} onChange={(e) => setI_pcs(e.target.value)} />
                            </FormGroup> : null} */}
                        </Row>
                        <Row>
                            {category === "vegetableAndFruit" || category === "meatChikenFish" ? <FormGroup>
                                <Label for="exampleSelect">
                                    {t('description.countryOfOrigin')}
                                </Label><br />
                                <FormText>
                                    {t('description.countryCode')}
                                </FormText>
                                <Input placeholder={t('description.countryOfOrigin')} type='text' value={i_origin} onChange={(e) => setI_Origin(e.target.value)} />
                            </FormGroup> : null}

                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    color="success"
                                    outline
                                    className="float-end"
                                    onClick={addHandle}
                                >
                                    {t('description.add')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={toggleHandle3}
                                >
                                    {t('description.close')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>}

        </Col>
    )
}

export default Product