import React, { useState } from 'react'
import hamburgerIcon from '../images/hamburger-menu.png'
import close from '../images/close.png'
import styled from 'styled-components'
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap'
import Logo from '../images/logo.png'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'
import classNames from 'classnames'
import { useSiteContext } from '../context/SiteContext'
import { MdOutlineLogout, MdOutlineFileUpload, MdOutlineAccountCircle, MdOutlineShoppingCart, MdOutlineHome, MdOutlinePersonAddAlt, MdOutlinePeopleAlt } from 'react-icons/md'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'

const StyledMenuContainer = styled.div`

position:fixed;
top:0;
left:0;
display:flex;
align-items:center;
height:100vh;
width:100vw;
justify-content:center;

.background-mask {
    position:fixed;
    top:0;
    left:0;
    background-color:#fff;
    opacity:0.9;
    height:100vh;
    width:100vw;
    z-index:99;
}

.menu-content-container {
    height:100%;
    width:100%;
    position:relative;
    z-index:99;
    margin-top:20%
}

.close {
    position:absolute;
    top:1em;
    right:1em;
    width:2em;
}
.menu {
    margin-top:3em;
}


`;

const MobileHeader = () => {
    const [visible, setVisible] = useState(false);
    const [isActiveMobile, setIsActiveMobile] = useState();

    const { loginInfo } = useSiteContext();

    const clickHandleA = () => {
        setIsActiveMobile("a")
        setVisible(false)
    }
    const clickHandleB = () => {
        setIsActiveMobile("b")
    }

    const { t, i18n } = useTranslation();

    const lngs = {
        en: { nativeName: 'English', nativeFlag: 'GBR' },
        tr: { nativeName: 'Turkish', nativeFlag: 'TUR' },
        de: { nativeName: 'Deutsch', nativeFlag: 'DEU' }
    };

    return (
        <Row className={["mobile-header", "hide-desktop"].join(' ')}>
            <Col xs="1" onClick={() => setVisible(true)}>
                <img src={hamburgerIcon} alt="Menu" className="hamburger-icon cursor-button " />
            </Col>
            <Col xs="10">
                <NavLink href="/">
                    <img src={Logo} alt="Logo" className="logo" />
                </NavLink>
            </Col>
            <hr />

            {visible && (
                <StyledMenuContainer>
                    <Row onClick={() => setVisible(false)} className="background-mask"></Row>
                    <Row id="mobileMenuContainer" className="menu-content-container">
                        <img onClick={() => setVisible(false)} src={close} alt="close" className="close" />
                        <Nav vertical pills className="menu">
                            <Row className="flags-row">
                                {Object.keys(lngs).map((lng) => (
                                    <>
                                        <Flag key={lng} code={lngs[lng].nativeFlag} className="native-flags" width={i18n.resolvedLanguage === lng ? '20px' : '15px'} height={i18n.resolvedLanguage === lng ? '20px' : '15px'} style={{ fontSize: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage(lng)} />
                                    </>
                                ))}
                            </Row>
                            <NavItem>
                                <Link to={"/"}>
                                    <NavLink className={classNames({ 'background-active': window.location.pathname === "/", })} onClick={() => setVisible(false)
                                    } ><MdOutlineHome className={classNames({
                                        'background-active home-active': window.location.pathname === "/",
                                    })} />{t('description.menuHome')}</NavLink>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link to={"/product"}>
                                    <NavLink className={classNames({
                                        'background-active': window.location.pathname === "/product",

                                    })} onClick={() => setVisible(false)}><MdOutlineShoppingCart className={classNames({
                                        'background-active product-active': window.location.pathname === "/product",
                                    })} />{t('description.menuProducts')}</NavLink>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link to={"/profile"} >
                                    <NavLink className={classNames({
                                        'background-active': window.location.pathname === "/profile",

                                    })} onClick={() => setVisible(false)}><MdOutlineAccountCircle className={classNames({
                                        'background-active profile-active': window.location.pathname === "/profile",
                                    })} />{t('description.menuProfile')}</NavLink>
                                </Link>
                            </NavItem>
                            {loginInfo && loginInfo[0].userLevel === "0" ? <NavItem>
                                <Link to={"/newproduct"} >
                                    <NavLink className={classNames({
                                        'background-active newproduct-active': window.location.pathname === "/newproduct",

                                    })} onClick={() => setVisible(false)
                                    }><MdOutlineFileUpload className={classNames({
                                        'background-active newproduct-active': window.location.pathname === "/newproduct",
                                    })} />{t('description.menuNewProduct')}</NavLink>
                                </Link>
                            </NavItem> : null}
                            {loginInfo && loginInfo[0].userLevel === "0" ?
                                <NavItem>
                                    <Link to={"/newuser"} >
                                        <NavLink className={classNames({
                                            'background-active newuser-active': window.location.pathname === "/newuser",

                                        })} onClick={() => setVisible(false)
                                        }><MdOutlinePersonAddAlt className={classNames({
                                            'background-active newuser-active': window.location.pathname === "/newuser",
                                        })} />{t('description.menuNewUser')}</NavLink>
                                    </Link>
                                </NavItem> : null}
                            {loginInfo && loginInfo[0]?.userLevel === "0" ? <NavItem>
                                <Link to={"/userlist"} >
                                    <NavLink className={classNames({
                                        'background-active newuser-active': window.location.pathname === "/userlist",

                                    })} onClick={() => setVisible(false)}><MdOutlinePeopleAlt className={classNames({
                                        'background-active newuser-active': window.location.pathname === "/userlist",
                                    })} />{t('description.menuUserList')}</NavLink>
                                </Link>
                            </NavItem> : null}
                            <NavItem>
                                <NavLink className='cursor-button' onClick={() => auth.signOut()}><MdOutlineLogout className={classNames({
                                    'background-active ': window.location.pathname === "/logout",
                                })} />{t('description.menuLogOut')}</NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                </StyledMenuContainer>
            )}
        </Row>
    )
}

export default MobileHeader