import React, { useContext, useEffect, useState } from 'react'
import { a, Row, Input, InputGroup, Button, Col } from 'reactstrap'
import ProductCart from './ProductCart'
import MobileProductCart from './MobileProductCart'
import Product from './Product'
import { useSiteContext } from '../context/SiteContext'
import { useTranslation } from 'react-i18next'

const ProductList = ({ id, title, imageURL }) => {

    const { urunler_, searchTerm, setSearchTerm, categoryInfo, setPattern } = useSiteContext()
    const [i_value, setI_value] = useState();

    const { t, i18n } = useTranslation();
    setPattern(undefined)

    useEffect(() => {
        const slider = document.querySelector('.product-list-filter-row');

        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
            mouseDown = false;
        };
        console.log(slider + "slider")
        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);

    }, [])



    return (
        <Row>
            <a xl="12" lg="12" >
                <Row xs="1" className="search-bar">
                    <a lg="6">
                        <InputGroup>
                            <Input
                                placeholder={t('description.searchPlaceHolder')}
                                type="search"
                                value={searchTerm.toLowerCase()}
                                autoFocus="autoFocus"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </InputGroup>
                    </a>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <div className="product-list-filter-row">
                        <div className="product-list-filter">
                            {categoryInfo && categoryInfo.map(category =>
                                <a xl="1" lg="2" md="2" sm="3" xs="3" style={{ color: i_value === category.id ? "white" : "#7a17bf", background: i_value === category.id ? "#7a17bf" : "#fff" }} onClick={() => setI_value(category.id)} className="cursor-button cat-list" > {t('description.' + category.id)} </a>
                            )}
                        </div></div>
                    {i_value === "all" ? urunler_.filter((urun) => urun.name.toLowerCase().includes(searchTerm)).map((urun, index) => (
                        <Product index={index} id={urun.id} name={urun.name} imgURL={urun.imgURL} category={urun.category} trName={urun.trName} />)) : urunler_.filter((urun) => urun.name.toLowerCase().includes(searchTerm.toLowerCase())).filter(urun => urun.category == i_value).map(
                            filteredUrun => (
                                <Product id={filteredUrun.id} name={filteredUrun.name} imgURL={filteredUrun.imgURL} category={filteredUrun.category} trName={filteredUrun.trName} />
                            )
                        )}
                </Row>
            </a>
            <a xl="3" lg="4" xs="12" className="px-0">
                <ProductCart id={id} title={title} imageURL={imageURL} />
                <MobileProductCart />
            </a>
        </Row>
    )
}
export default ProductList