import React, { useState } from 'react'
import { Nav, NavItem, NavLink, Row } from 'reactstrap'
import { auth } from '../firebase'
import Logo from '../images/logo.png'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { MdOutlineLogout, MdOutlineFileUpload, MdOutlineAccountCircle, MdOutlineShoppingCart, MdOutlineHome, MdOutlinePersonAddAlt, MdOutlinePeopleAlt } from 'react-icons/md'
import { useSiteContext } from '../context/SiteContext'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'


const Navbar = () => {

    const {t, i18n} = useTranslation();

    const [isActive, setIsActive] = useState();

    const { usersInfo, loginInfo, loginEmail } = useSiteContext();

    const lngs = {
        en: { nativeName: 'English', nativeFlag: 'GBR' },
        tr: { nativeName: 'Turkish', nativeFlag: 'TUR' },
        de: { nativeName: 'Deutsch', nativeFlag: 'DEU' }
      };


    return (
        <> {loginInfo && loginInfo[0].userLevel === "-1" ? null : <Row className={["nav-bar", "hide-mobile"].join(' ')}>
        <Link to={"/"}>
            <NavLink >
                <img src={Logo} alt="Logo" className="logo" />
            </NavLink>
        </Link>
        <hr className='hide-mobile'/>
        <Row className="flags-row">
            {Object.keys(lngs).map((lng) => (
                <>
                    <Flag key={lng} code={lngs[lng].nativeFlag} className="native-flags" width={i18n.resolvedLanguage === lng ? '20px' : '15px'} height={i18n.resolvedLanguage === lng ? '20px' : '15px'} style={{ fontSize: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage(lng)} />
                </>
            ))}
        </Row>
        <hr className='hide-mobile'/>
        <Nav vertical pills className="menu">
            <NavItem>
                <Link to={"/"}>
                    <NavLink className={classNames({
                        'background-active home-active': window.location.pathname === "/",

                    })} onClick={() => setIsActive("a")} ><MdOutlineHome className={classNames({
                        'background-active home-active': window.location.pathname === "/",
                    })} />{t('description.menuHome')}</NavLink>
                </Link>
            </NavItem>
            <NavItem>
                <Link to={"/product"} >
                    <NavLink className={classNames({
                        'background-active product-active': window.location.pathname === "/product",

                    })} onClick={() => setIsActive("b")}><MdOutlineShoppingCart className={classNames({
                        'background-active product-active': window.location.pathname === "/product",
                    })} />{t('description.menuProducts')}</NavLink>
                </Link>
            </NavItem>
            {loginInfo && loginInfo[0]?.userLevel === "0" ? <NavItem>
                <Link to={"/newproduct"} >
                    <NavLink className={classNames({
                        'background-active newproduct-active': window.location.pathname === "/newproduct",

                    })} onClick={() => setIsActive("f")}><MdOutlineFileUpload className={classNames({
                        'background-active newproduct-active': window.location.pathname === "/newproduct",
                    })} />{t('description.menuNewProduct')}</NavLink>
                </Link>
            </NavItem> : null}
            {loginInfo && loginInfo[0]?.userLevel === "0" ? <NavItem>
                <Link to={"/userlist"} >
                    <NavLink className={classNames({
                        'background-active newuser-active': window.location.pathname === "/userlist",

                    })} onClick={() => setIsActive("d")}><MdOutlinePeopleAlt className={classNames({
                        'background-active newuser-active': window.location.pathname === "/userlist",
                    })} />{t('description.menuUserList')}</NavLink>
                </Link>
            </NavItem> : null}
            {loginInfo && loginInfo[0]?.userLevel === "0" ?
                <NavItem>
                    <Link to={"/newuser"} >
                        <NavLink className={classNames({
                            'background-active newuser-active': window.location.pathname === "/newuser",

                        })} onClick={() => setIsActive("e")}><MdOutlinePersonAddAlt className={classNames({
                            'background-active newuser-active': window.location.pathname === "/newuser",
                        })} />{t('description.menuNewUser')}</NavLink>
                    </Link>
                </NavItem> : null}

            <NavItem>
                <Link to={"/profile"} >
                    <NavLink className={classNames({
                        'background-active profile-active': window.location.pathname === "/profile",

                    })} onClick={() => setIsActive("c")}><MdOutlineAccountCircle className={classNames({
                        'background-active profile-active': window.location.pathname === "/profile",
                    })} />{t('description.menuProfile')}</NavLink>
                </Link>
            </NavItem>
            <NavItem>
                <NavLink className='cursor-button' onClick={() => auth.signOut()}><MdOutlineLogout className={classNames({
                    'background-active ': window.location.pathname === "/logout",
                })} />{t('description.menuLogOut')}</NavLink>
            </NavItem>
        </Nav>
    </Row>} </>
    )
}

export default Navbar