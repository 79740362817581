import React from 'react'
import { Row, Input, Col, Table, Button, InputGroup, Label } from 'reactstrap'
import { useSiteContext } from '../context/SiteContext'
import logo from '../images/logo.png'
import db from '../firebase'
import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';
import toast from 'react-hot-toast'

const UserList = () => {

    const { t, i18n } = useTranslation();


    const { usersInfo, setPattern, newdate } = useSiteContext();
    const [i_radio, setI_radio] = useState("0")


    setPattern(undefined)

    const [patSearch, setPatSearch] = useState('')

    var endPeriodDate;

    const addHandle = (userUid, userPeriodEnd) => {
        if (i_radio === "0") {
            toast.error(`${t('description.emptyPeriod')}`);
            return;
        } else {
            var x = parseInt(i_radio);
            var xy = userPeriodEnd.split(".");
            var CurrentDate = new Date(xy[2], (xy[1] - 1) + x, xy[0]);
            var CurrentDateNew = new Date(CurrentDate).toISOString();
            var endPeriodYear = CurrentDateNew.split("T")[0].split("-")[0];
            var endPeriodMonth = CurrentDateNew.split("T")[0].split("-")[1];
            var endPeriodDay = CurrentDateNew.split("T")[0].split("-")[2];
            var endPeriodNewDate = endPeriodDay + "." + endPeriodMonth + "." + endPeriodYear

            db.collection("profile/").doc("profile_" + userUid).update({
                periodEnd: endPeriodNewDate,
                sortPeriodEnd: CurrentDate,
            })
        }

    }

    console.log(i_radio)
    return (
        <>
            <Row>
                <Row xs="1" className="search-bar">
                    <Col lg="6">
                        <InputGroup>
                            <Input
                                placeholder={t('description.searchPlaceHolder')}
                                type="search"
                                value={patSearch}
                                autoFocus="autoFocus"
                                onChange={(e) => setPatSearch(e.target.value)}
                            />
                        </InputGroup>
                    </Col>

                </Row>
                <Row className="justify-content-center hide-mobile">
                    {usersInfo && usersInfo.filter((user) => user.email.toLowerCase().includes(patSearch)).map((user, index) => (


                        <Col key={index} xl="11" xs="11" className="home-pattern">
                            <Row>
                                <Col className="home-pattern-title"><h4>{user.email}</h4></Col>
                                {/* <Col>
                                    <InputGroup>
                                        <Input
                                            id="exampleSelect"
                                            name={index}
                                            type="select"
                                            value={i_radio}
                                            onChange={(e) => setI_radio(e.target.value)}
                                        >
                                            <option value={"0"}>
                                                {t('description.selectPeriodTime')}
                                            </option>
                                            <option value={"1"}>
                                                1 {t('description.month')}
                                            </option>
                                            <option value={"3"}>
                                                3 {t('description.month')}
                                            </option >
                                            <option value={"6"}>
                                                6 {t('description.month')}
                                            </option >
                                            <option value={"12"}>
                                                12 {t('description.month')}
                                            </option >
                                        </Input>
                                    </InputGroup></Col> */}


                            </Row>
                            <Row>
                                <Col className="home-pattern-image"><img src={user.logoURL === "" ? logo : user.logoURL} width="150" /></Col>
                                <Col className="home-pattern-right">
                                    <Row className="home-pattern-sd">{t('description.periodStartDate')}: {user.periodStart}</Row>
                                    <Row className="home-pattern-ed">{t('description.periodEndDate')}: {user.periodEnd}</Row>
                                    <Row>
                                        <Col xl="9" className="login-input" style={{ alignSelf: 'center', paddingLeft: 0 }}>
                                            <Row>
                                                <Col xl="3" style={{ paddingLeft: 0 }}>
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("1")} />
                                                    <Label check>
                                                        1 {t('description.month')}
                                                    </Label>
                                                </Col>
                                                <Col xl="3">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("3")} />
                                                    <Label check>
                                                        3 {t('description.month')}
                                                    </Label>
                                                </Col>
                                                <Col xl="3">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("6")} />
                                                    <Label check>
                                                        6 {t('description.month')}
                                                    </Label>
                                                </Col>
                                                <Col xl="3">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("12")} />
                                                    <Label check>
                                                        12 {t('description.month')}
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="home-pattern-title"><Button onClick={() => addHandle(user.uid, user.periodEnd)} > {t('description.addPeriodTime')} </Button></Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                    ))}


                </Row>
                <Row className="justify-content-center hide-desktop">
                    {usersInfo && usersInfo.filter((user) => user.email.toLowerCase().includes(patSearch)).map((user, index) => (


                        <Col key={index} xl="11" xs="11" className="home-pattern">
                            <Row>
                                <Col className="home-pattern-title"><h4>{user.email}</h4></Col>
                            </Row>
                            <Row>
                                <Col className="home-pattern-image"><img src={user.logoURL === "" ? logo : user.logoURL} width="150" /></Col>
                            </Row>
                            <Row>
                                <Col className="home-pattern-right">
                                    <Row className="home-pattern-sd">{t('description.periodStartDate')}: {user.periodStart}</Row>
                                    <Row className="home-pattern-ed">{t('description.periodEndDate')}: {user.periodEnd}</Row>
                                    <Row>
                                        <Col xl="9" className="login-input" style={{ alignSelf: 'center', paddingLeft: 0, marginTop: 10 }}>
                                            <Row>
                                                <Col xl="3" lg="3" md="6" sm="6" xs="6">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("1")} />
                                                    <Label check>
                                                        1 {t('description.month')}
                                                    </Label>
                                                </Col>
                                                <Col xl="3" lg="3" md="6" sm="6" xs="6">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("3")} />
                                                    <Label check>
                                                        3 {t('description.month')}
                                                    </Label>
                                                </Col>
                                                <Col xl="3" lg="3" md="6" sm="6" xs="6">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("6")} />
                                                    <Label check>
                                                        6 {t('description.month')}
                                                    </Label>
                                                </Col>
                                                <Col xl="3" lg="3" md="6" sm="6" xs="6">
                                                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("12")} />
                                                    <Label check>
                                                        12 {t('description.month')}
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="home-pattern-button"><Button onClick={() => addHandle(user.uid, user.periodEnd)} > {t('description.addPeriodTime')} </Button></Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                    ))}


                </Row>
            </Row>
        </>

    )
}

export default UserList