import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Button } from 'reactstrap'
import close from '../images/close.png'
import CartProduct from './CartProduct'



const MobileProductCart = () => {

    const openList = () => {
        document.getElementById("cartListMobile").style.height = "80%";
        document.getElementById("backgroundMaskMobile").style.height = "100%";
    }
    const closeList = () => {
        document.getElementById("cartListMobile").style.height = "0";
        document.getElementById("backgroundMaskMobile").style.height = "0%";
    }

    return (
        <Row className={["mobile-product-cart", "hide-mobile", "hide-desktop"].join(' ')}>
            <Row onClick={openList}>
                <Col xs="1">0</Col>
                <Col xs="10">Product List</Col>
            </Row>
            <Row onClick={closeList} id="backgroundMaskMobile" className="background-mask"></Row>
            <Row id="cartListMobile" className="cart-list">

                <img onClick={closeList} src={close} alt="close" className="close" />
                <Row className="cart">
                    <CartProduct />
                    <CartProduct />
                    <CartProduct />
                    <CartProduct />
                    <CartProduct />
                    <CartProduct />
                    <CartProduct />
                    <CartProduct />
                </Row>
                <Row className="mobile-cart-button" >
                    <Link to={"/pattern"} className="p-0">
                        <Button style={{ width: '100%' }}> Create Template</Button>
                    </Link>
                </Row>
            </Row >

        </Row >

    )
}

export default MobileProductCart