import React, { useState, useContext, useEffect } from 'react'
import { useSiteContext } from '../context/SiteContext'
import { Col, Label, Row, Input, Button, Form } from 'reactstrap'
import db, { register } from "../firebase"
import { nanoid } from 'nanoid'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'


const NewUser = () => {

  const { loginInfo, newdate, setPattern } = useSiteContext();

  setPattern(undefined)

  const navigate = useNavigate()


  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')

  const [i_radio, setI_radio] = useState("0")

  const [i_periodEnd, setI_periodEnd] = useState()

  const { t, i18n } = useTranslation();

  const endDay = newdate.split(".")[0];
  var endMonth = newdate.split(".")[1];
  const endYear = newdate.split(".")[2];

  var endMonth_;

  var endPeriodDate;

  var x = parseInt(i_radio)

  const handleSubmit = async e => {
    e.preventDefault()

    if (i_radio === "0") {
      toast.error(`${t('description.periodEmpty')}`);
      return;
    } else {
      const user = await register(email, password);
      var x = parseInt(i_radio);
      var CurrentDate = new Date();
      CurrentDate.setMonth(CurrentDate.getMonth() + x);
      var endPeriodYear = CurrentDate.toISOString().split("T")[0].split("-")[0]
      var endPeriodMonth = CurrentDate.toISOString().split("T")[0].split("-")[1]
      var endPeriodDay = CurrentDate.toISOString().split("T")[0].split("-")[2]
      endPeriodDate = endPeriodDay.toString() + "." + endPeriodMonth.toString() + "." + endPeriodYear.toString();
      var from = endPeriodDate.split(".")
      var f = new Date(from[2], from[1] - 1, from[0])
      db.collection("profile/").doc("profile_" + JSON.parse(localStorage.getItem("user"))?.uid).set({
        uid: JSON.parse(localStorage.getItem("user"))?.uid,
        address: "",
        email: JSON.parse(localStorage.getItem("user"))?.email,
        logoURL: "",
        period: i_radio ? i_radio : "" ,
        periodEnd: endPeriodDate,
        periodStart: newdate,
        phone: "",
        userLevel: "1",
        sortPeriodStart: new Date(),
        sortPeriodEnd: new Date(new Date().setMonth(new Date().getMonth() + x)),
      })
      navigate('/', {
        replace: true
    })
    }

  }





  return (
    <Row>
      {loginInfo && loginInfo[0].userLevel === "0" ?

        <Col xl="6" className="login">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="2" className="login-input align-self-center">
                <Label> {t('description.email')}: </Label>
              </Col>
              <Col xl="10" className="login-input">
                <Input placeholder={t('description.email')} type="text" value={email} onChange={e => setEmail(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col xl="2" className="login-input align-self-center">
                <Label>{t('description.password')}:</Label>
              </Col>
              <Col xl="10" className="login-input">
                <Input placeholder={t('description.password')} type="password" value={password} onChange={e => setPassword(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col xl="3" className="login-input align-self-center">
                <Label>{t('description.accountPeriod')}:</Label>
              </Col>
              <Col xl="9" className="login-input">
                <Row>
                  <Col xl="3">
                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("1")} />
                    <Label check>
                      1 {t('description.month')}
                    </Label>
                  </Col>
                  <Col xl="3">
                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("3")} />
                    <Label check>
                      3 {t('description.month')}
                    </Label>
                  </Col>
                  <Col xl="3">
                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("6")} />
                    <Label check>
                      6 {t('description.month')}
                    </Label>
                  </Col>
                  <Col xl="3">
                    <Input type="radio" name="period" className="period-radio-button" onClick={() => setI_radio("12")} />
                    <Label check>
                      12 {t('description.month')}
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="login-button">
                <Button disabled={!email || !password} type="submit" > {t('description.register')} </Button>
              </Col>
            </Row>
          </Form>
        </Col > : <div>Accsess Denied</div>}
    </Row>
  )
}

export default NewUser