import { createContext, useState, useSelector, useContext, useRef, useEffect } from "react";
import AbstractModalHeader from "react-bootstrap/esm/AbstractModalHeader";
import db, { auth } from '../firebase'



const SiteContext = createContext()

export const useSiteContext = () => useContext(SiteContext);

const SiteProvider = ({ children }) => {

  const oldDate = new Date().toISOString();
  const newDate = oldDate.split("T")[0];
  const newYear_ = newDate.split("-")[0];
  const newMonth_ = newDate.split("-")[1];
  const newDay_ = newDate.split("-")[2];
  const newdate = newDay_ + "." + newMonth_ + "." + newYear_;

  const [user, setUser] = useState()
  const [i_campTitle, setI_campTitle] = useState()
  const [i_startDate, setI_startDate] = useState()
  const [i_endDate, setI_endDate] = useState()
  const [loginInfo, setLoginInfo] = useState()
  const [loginEmail, setLoginEmail] = useState()
  const [loginPassword, setLoginPassword] = useState()

  const [isLogin, setIsLogin] = useState()

  const [imageURL, setImageURL] = useState();

  const [cartLeng, setCartLeng] = useState();

  const [urunler_, setUrunler_] = useState([]);

  const [pattern, setPattern] = useState()

  const [toCart, setToCart] = useState(false);


  const [cartUrunler_, setCartUrunler_] = useState([]);

  const [findKeyArray, setFindKeyArray] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [isPopen, setIsPopen] = useState(false);

  const [swCheck, setCheck] = useState(false);

  const [findKey, setFindKey] = useState(false);

  const [patternInfo, setPatternInfo] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState([]);

  const [profileInfo, setProfileInfo] = useState([{
    id: null, address: null, logoURL: null
  }]);

  const [usersInfo, setUsersInfo] = useState()

  const [patternBox, setPatternBox] = useState([]);

  const [_productIds, set_ProductIds] = useState([]);


  const [productIds, setProductIds] = useState([]);


  const [newProductIds, setNewProductIds] = useState([]);


  const [parsedProducts_, setParsedProducts_] = useState([]);

  const radioHandle = () => {
    setCheck(swCheck === true ? false : true)
  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const firstUpdate = useRef(true);


  useEffect(() => {

    if (firstUpdate.current) {
      firstUpdate.current = false;
      if (JSON.parse(localStorage.getItem("productIds"))?.length > 0) {
        const parsedProducts = JSON.parse(localStorage.getItem("productIds"));
        if (parsedProducts != null) {

          setParsedProducts_(parsedProducts)
          setNewProductIds(parsedProducts)
          setCartUrunler_(parsedProducts)
        }
      }  
      // console.log("useEffect1 " + localStorage.getItem("productIds"))
      return;
    }
    //  }
    else {
      // console.log("useEffect4 " + newProductIds)
      // console.log("useEffect 4.1 " + JSON.stringify(newProductIds))
      // setNewProductIds(newProductIds)
      setCartUrunler_(newProductIds)
      localStorage.setItem("productIds", JSON.stringify(newProductIds));


    }
  }, [productIds]);

  
  const firstUpdate_ = useRef(true);
  useEffect(() => {

    if (firstUpdate_.current) {
      firstUpdate_.current = false;
      return;
    }
    //  }
    else {
      localStorage.setItem("productIds", JSON.stringify(cartUrunler_));
      setNewProductIds(JSON.parse(localStorage.getItem("productIds")))
  
    }
  }, [cartUrunler_]);


  useEffect(() => {
    db.collection("product").onSnapshot(snapshot => (
      setUrunler_(snapshot.docs.map(doc => (
        {
          id: doc.id,
          name: doc.data().name,
          trName: doc.data().trName,
          desc: doc.data().desc,
          qty: doc.data().qty,
          imgURL: doc.data().imgURL,
          category: doc.data().category,
        }
      )))
    ))
  }, []);

  useEffect(() => {
    db.collection("pattern/" + JSON.parse(localStorage.getItem("user"))?.uid + "/" + JSON.parse(localStorage.getItem("user"))?.uid).orderBy("createDateOrder", "desc").onSnapshot(snapshot => (
      setPatternInfo(snapshot.docs.map(doc => (
        {
          id: doc.id,
          title: doc.data().title,
          startDate: doc.data().startDate,
          endDate: doc.data().endDate,
          createDate: doc.data().createDate,
          imageURL: doc.data().imageURL,
          createDateOrder: doc.data().createDateOrder,
        }
      )))
    ))

  }, []);
  useEffect(() => {
    db.collection("profile/").orderBy("sortPeriodEnd", "asc").onSnapshot(snapshot => {
      return setUsersInfo(snapshot.docs.map(doc => (
        {
          id: doc.data().id,
          address: doc.data().address,
          logoURL: doc.data().logoURL,
          email: doc.data().email,
          password: doc.data().password,
          phone: doc.data().phone,
          period: doc.data().period,
          periodStart: doc.data().periodStart,
          periodEnd: doc.data().periodEnd,
          userLevel: doc.data().userLevel,
          sortPeriodStart: doc.data().sortPeriodStart,
          sortPeriodEnd: doc.data().sortPeriodEnd,
          uid:doc.data().uid
        }

      )))
    })
  }, []);



  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) != null) {
      db.collection("profile/").where("uid", "==", JSON.parse(localStorage.getItem("user"))?.uid).onSnapshot(snapshot => {
        return setLoginInfo(snapshot.docs.map(doc => (
          {
            id: doc.data().id,
            address: doc.data().address,
            logoURL: doc.data().logoURL,
            email: doc.data().email,
            password: doc.data().password,
            phone: doc.data().phone,
            period: doc.data().period,
            periodStart: doc.data().periodStart,
            periodEnd: doc.data().periodEnd,
            userLevel: doc.data().userLevel,
            sortPeriodStart: doc.data().sortPeriodStart,
            sortPeriodEnd: doc.data().sortPeriodEnd,

          }

        )))
      })
      return;
    }
  }, [user]);

  useEffect(() => {
    db.collection("profile/" + "profile/" + JSON.parse(localStorage.getItem("user"))?.uid).onSnapshot(snapshot => (
      setProfileInfo(snapshot.docs.map(doc => (
        {
          id: doc.data().id,
          address: doc.data().address,
          logoURL: doc.data().logoURL,
          email: doc.data().email,
          phone: doc.data().phone,
          period: doc.data().period,
          periodStart: doc.data().periodStart,
          periodEnd: doc.data().periodEnd,
          userLevel: doc.data().userLevel

        }

      )))
    ))
  }, []);

  useEffect(() => {
    db.collection("category/").onSnapshot(snapshot => (
      setCategoryInfo(snapshot.docs.map(doc => (
        {
          id: doc.data().id,
        }

      )))
    ))
  }, []);



  const data = {
    urunler_,
    setUrunler_,
    cartUrunler_,
    setCartUrunler_,
    findKey,
    setFindKey,
    findKeyArray,
    setFindKeyArray,
    togglePopup,
    isOpen,
    setIsOpen,
    isPopen,
    setIsPopen,
    radioHandle,
    swCheck,
    setCheck,
    searchTerm,
    setSearchTerm,
    _productIds,
    set_ProductIds,
    productIds,
    setProductIds,
    parsedProducts_,
    newProductIds,
    setNewProductIds,
    patternBox,
    setPatternBox,
    patternInfo,
    setPatternInfo,
    i_campTitle,
    setI_campTitle,
    i_startDate,
    setI_startDate,
    i_endDate,
    setI_endDate,
    toCart,
    setToCart,
    pattern,
    setPattern,
    imageURL,
    setImageURL,
    cartLeng,
    setCartLeng,
    profileInfo,
    setProfileInfo,
    usersInfo,
    setUsersInfo,
    loginInfo,
    setLoginInfo,
    loginEmail,
    setLoginEmail,
    loginPassword,
    setLoginPassword,
    user,
    setUser,
    isLogin,
    setIsLogin,
    oldDate,
    newDate,
    newYear_,
    newMonth_,
    newDay_,
    newdate,
    categoryInfo,
    setCategoryInfo
  }


  return (
    <SiteContext.Provider value={data} >
      {children}
    </SiteContext.Provider>
  );
}


export default SiteProvider;