import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          description: {
            menuHome: 'Home',
            menuProducts: 'Products',
            menuNewProduct: 'New Product',
            menuUserList: 'User List',
            menuNewUser: 'New User',
            menuProfile: 'Profile',
            menuLogOut: 'Log Out',
            searchPlaceHolder: 'Search..',
            emptyPattern: 'There is not pattern yet',
            createDate: 'Create Date: ',
            campaingStartDate: 'Campaing Start Date: ',
            campaingEndDate: 'Campaing End Date: ',
            vegetable: 'Vegetables',
            fruit: 'Fruits',
            drinks: 'Drinks',
            butcher: 'Butcher',
            snack: 'Snacks',
            vegetableAndFruit: 'Vegetable and Fruit',
            meatChickenFish: 'Meat/Chicken/Fish',
            milkAndBreakfast: 'Milk and Breakfast',
            basicFood: 'Basic Food',
            frozenProducts: 'Frozen Products',
            breadAndBakeryProducts: 'Bread and Bakery Products',
            detergentAndCleaning: 'Detergent and Cleaning',
            personalCareCosmetics: 'Personal Care Cosmetics',
            homeLife: 'Home Life',
            all: 'All',
            selectUnit: 'Select Unit',
            oldPrice: 'Old Price',
            newPrice: 'New Price',
            discountedPrice: 'Discounted Price',
            price: 'Price',
            sale: 'Sale',
            weight: 'Weight',
            countryOfOrigin: 'Country of Origin',
            add: 'Add',
            close: 'Close',
            priceValidToast: 'Price cannot be empty',
            productList: 'Product List',
            createTemplate: 'Create Template',
            category: 'Category',
            name: 'Name',
            pcsNote: 'Only avaible 1-3-6 pcs product',
            userSettings: 'User Settings',
            subsPeriod: 'Subscription Period',
            periodStartDate: 'Period Start Date',
            periodEndDate: 'Period End Date',
            email: 'Email',
            phone: 'Phone',
            password: 'Password',
            update: 'Update',
            address: 'Address',
            choosePattern: 'First you have to choose the pattern',
            selectPattern: 'Select Pattern',
            updateProduct: 'Update Product',
            saveAndDownload: 'Save & Download',
            campaingTitle: 'Campaing Title',
            startDate: 'Start Date',
            endDate: 'End Date',
            priceEmpty: 'Price cannot be empty',
            priceNewPrice: 'Discounted price cannot be higher old price',
            weightEmpty: 'Weight cannot be empty',
            oldPriceEmpty: 'Old Price cannot be empty',
            unitEmpty: 'Must select Unit',
            productAdded: 'Added',
            alreadyHave: 'Already have it!',
            productRemoved: 'Removed',
            campaingTitleEmpty: 'Campaing title cannot be empty',
            campaingDateEmpty: 'Campaing date cannot be empty',
            campaingDateWrong: 'Please check selected dates',
            selectCategory: 'Select Category',
            emptyCategory: 'Please check category',
            emptyProductName: 'Please check product name',
            newProductAdded: 'New Product Added',
            sureForDelete: 'is will permanently deleted. Are you sure?',
            delete: 'Delete',
            periodEmpty: 'Please Select a Period',
            month: 'Month',
            uploadImage: 'Click here to upload product image',
            saveLogo: 'Save Logo',
            accountPeriod: 'Account Period',
            register: 'Register',
            deleteLogo: 'Delete Logo',
            addPeriodTime: 'Add Time',
            selectPeriodTime: 'Select Period',
            emptyPeriod: 'Please Select Period',
            newCategory: 'New Category',
            pcs: 'Pcs',
            piece: 'Piece',
            countryCode: '(Enter as 2 letter country code. Ex: TR, DE, EN)',
            updateProduct: 'Update Product'
          }
        }
      },
      tr: {
        translation: {
          description: {
            menuHome: 'Anasayfa',
            menuProducts: 'Ürünler',
            menuNewProduct: 'Ürün Ekleme',
            menuUserList: 'Kullanıcı Listesi',
            menuNewUser: 'Kullanıcı Ekleme',
            menuProfile: 'Profil',
            menuLogOut: 'Çıkış Yap',
            searchPlaceHolder: 'Ara..',
            emptyPattern: 'Henüz bir şablon bulunmuyor',
            createDate: 'Oluşturma Tarihi: ',
            campaingStartDate: 'Kampanya Başlangıç Tarihi: ',
            campaingEndDate: 'Kampanya Bitiş Tarihi: ',
            vegetable: 'Sebzeler',
            fruit: 'Meyveler',
            drinks: 'İçecekler',
            butcher: 'Kasap',
            snack: 'Atıştırmalık',
            vegetableAndFruit: 'Sebze ve Meyve',
            meatChickenFish: 'Et/Tavuk/Balık',
            milkAndBreakfast: 'Süt ve Kahvaltılık',
            basicFood: 'Temel Gıda',
            frozenProducts: 'Donuk Ürünler',
            breadAndBakeryProducts: 'Ekmek ve Unlu Mamüller',
            detergentAndCleaning: 'Deterjan ve Temizlik',
            personalCareCosmetics: 'Kişisel Bakım Kozmetik',
            homeLife: 'Ev Yaşam',
            all: 'Tümü',
            selectUnit: 'Birim Seçiniz',
            oldPrice: 'Eski Fiyat',
            newPrice: 'Yeni Fiyat',
            discountedPrice: 'İndirimli Fiyat',
            price: 'Fiyat',
            sale: 'İndirim',
            weight: 'Miktar',
            countryOfOrigin: 'Menşei',
            add: 'Ekle',
            close: 'Kapat',
            productList: 'Ürün Listesi',
            priceValidToast: 'Fiyat boş olamaz',
            createTemplate: 'Şablon Oluştur',
            category: 'Kategori',
            name: 'Ürün İsmi',
            pcsNote: 'Sadece 1-3-6 adet ürün',
            userSettings: 'Kullanıcı Ayarları',
            subsPeriod: 'Abonelik Periyodu',
            periodStartDate: 'Periyod Başlangıç Tarihi',
            periodEndDate: 'Periyod Bitiş Tarihi',
            email: 'E-posta',
            phone: 'Telefon',
            password: 'Şifre',
            update: 'Güncelle',
            address: 'Adres',
            choosePattern: 'Öncelikle şablon seçmelisiniz!',
            selectPattern: 'Şablon Seçin',
            updateProduct: 'Ürünleri Güncelle',
            saveAndDownload: 'Kaydet & İndir',
            campaingTitle: 'Kampanya Başlığı',
            startDate: 'Başlangıç Tarihi',
            endDate: 'Bitiş Tarihi',
            priceEmpty: 'Fiyat boş olamaz',
            priceNewPrice: 'İndirimli fiyat eski fiyattan büyük olamaz!',
            weightEmpty: 'Ürün miktarı boş olamaz',
            oldPriceEmpty: 'Eski fiyat boş olamaz!',
            unitEmpty: 'Birim Seçilmeli',
            productAdded: 'Eklendi',
            alreadyHave: 'Şablonda bulunuyor!',
            productRemoved: 'Kaldırıldı',
            campaingTitleEmpty: 'Kampanya başlığı boş olamaz!',
            campaingDateEmpty: 'Kampanya tarihleri boş olamaz!',
            campaingDateWrong: 'Tarihleri kontrol edin',
            selectCategory: 'Kategori Seçin',
            emptyCategory: 'Kategori seçiniz',
            emptyProductName: 'Ürün ismi giriniz',
            newProductAdded: 'Yeni Ürün Eklendi',
            sureForDelete: 'Kalıcı olarak silinicektir. Onaylıyor musunuz?',
            delete: 'Sil',
            periodEmpty: 'Periyod seçiniz',
            month: 'Ay',
            uploadImage: 'Görsel yüklemek için tıklayınız',
            saveLogo: 'Logoyu Kaydet',
            accountPeriod: 'Hesap Periyodu',
            register: 'Kayıt Et',
            deleteLogo: 'Logoyu Sil',
            addPeriodTime: 'Süre Ekle',
            selectPeriodTime: 'Periyod Seçin',
            emptyPeriod: 'Periyod Seçiniz',
            newCategory: 'Yeni Kategori',
            pcs: 'Ad',
            piece: 'Adet',
            countryCode: '(2 harfli ülke kodu olarak giriniz. Örn: TR, DE, EN)',
            updateProduct: 'Ürün Güncelle'
          }
        }
      },
      de: {
        translation: {
          description: {
            menuHome: 'Startseite',
            menuProducts: 'Produkte',
            menuNewProduct: 'Produkt hinzufügen',
            menuUserList: 'Benutzerliste',
            menuNewUser: 'Benutzer hinzufügen',
            menuProfile: 'Profil',
            menuLogOut: 'Abmelden',
            searchPlaceHolder: 'Suche..',
            emptyPattern: 'Noch keine Vorlagen verfügbar',
            button1: 'Diesen Beitrag bearbeiten',
            createDate: 'Erstellt Datum: ',
            campaingStartDate: 'Startdatum der Kampagne: ',
            campaingEndDate: 'Enddatum der Kampagne: ',
            drinks: 'Getränke',
            snack: 'Süßes & Salziges',
            vegetableAndFruit: 'Obs und Gemüse',
            meatChickenFish: 'Fleisch/Hühner/Fisch',
            milkAndBreakfast: 'Kühlwaren',
            basicFood: 'Grundnahrungsmittel',
            frozenProducts: 'Tiefkühl',
            breadAndBakeryProducts: 'Brot & Gebäck',
            detergentAndCleaning: 'Waschmittel und Reinigung',
            personalCareCosmetics: 'Pflege',
            homeLife: 'Haushalt',
            all: 'Alles',
            selectUnit: 'Einheit auswählen',
            oldPrice: 'Alter Preis',
            newPrice: 'Neuer Preis',
            discountedPrice: 'Aktion Preis',
            price: 'Preis',
            sale: 'Rabatt',
            weight: 'Gewicht',
            countryOfOrigin: 'Herkunft',
            add: 'Hinzufügen',
            close: 'Schließen',
            priceValidToast: 'Der Preis darf nicht leer sein',
            productList: 'Produktliste',
            createTemplate: 'Vorlage erstellen',
            category: 'Kategorie',
            name: 'Produktname',
            pcsNote: 'Nur 1-3-6 Stk Produkte',
            userSettings: 'Benutzereinstellungen',
            subsPeriod: 'Abonnementzeitraum',
            periodStartDate: 'Startdatum des Zeitraums',
            periodEndDate: 'Periodenenddatum',
            email: 'Email',
            phone: 'Telefon',
            password: 'Passwort',
            update: 'Aktualisieren',
            address: 'Adresse',
            choosePattern: 'Sie müssen zuerst eine Vorlage auswählen!',
            selectPattern: 'Vorlage auswählenn',
            updateProduct: 'Produkte aktualisieren',
            saveAndDownload: 'Speichern & Herunterladen',
            campaingTitle: 'Kampagnentitel',
            startDate: 'Startdatum',
            endDate: 'Enddatum',
            priceEmpty: 'Der Preis darf nicht leer sein',
            priceNewPrice: 'Der Verkaufspreis darf nicht höher sein als der alte Preis!',
            weightEmpty: 'Artikelmenge darf nicht leer sein',
            oldPriceEmpty: 'Alter Preis darf nicht leer sein!',
            unitEmpty: 'Einheit muss ausgewählt werden',
            productAdded: 'Hinzugefügt',
            alreadyHave: 'Es ist in der Vorlage!',
            productRemoved: 'Entfernt',
            campaingTitleEmpty: 'Der Kampagnentitel darf nicht leer sein!',
            campaingDateEmpty: 'Kampagnendaten dürfen nicht leer sein!',
            campaingDateWrong: 'Überprüfen Sie die Termine',
            selectCategory: 'Kategorie auswählen',
            emptyCategory: 'Wählen Sie eine Kategorie',
            emptyProductName: 'Geben Sie den Produktnamen ein',
            newProductAdded: 'Neues Produkt hinzugefügt',
            sureForDelete: 'Es wird endgültig gelöscht. Bestätigen Sie?',
            delete: 'Löschen',
            periodEmpty: 'Zeitraum auswählen',
            month: 'Monat',
            uploadImage: 'Klicken um Bild hochzuladen',
            saveLogo: 'Logo speichern',
            accountPeriod: 'Kontozeitraum',
            register: 'Registrieren',
            deleteLogo: 'Logo löschen',
            addPeriodTime: 'Zeit hinzufügen',
            selectPeriodTime: 'Zeitraum auswählen',
            emptyPeriod: 'Bitte Zeitraum auswählen',
            newCategory: 'Neue Kategorie',
            pcs: 'Pcs',
            piece: 'Stück',
            countryCode: '(Als 2-Buchstaben-Ländercode eingeben. Beispiel: TR, DE, FR)',
            updateProduct: 'Produkt aktualisieren'
          }
        }
      }
    }
  });

export default i18n;