import React, { useState } from 'react'
import { Col, Row, Input, Button, Form } from 'reactstrap'
import Logo from '../images/logo.png'
import { login } from "../firebase"
import { useNavigate } from 'react-router'
import { useSiteContext } from '../context/SiteContext'


const Login = () => {


    const {setLoginEmail} = useSiteContext()

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async e => {
        e.preventDefault()
        const user = await login(email, password)
        if (user) {
            navigate('/', {
                replace: true
            })
        }
    }

    return (
        <Col xl="4" className="login">
            <Form onSubmit={handleSubmit}>
                <Row >
                    <Col className="login-logo" ><img src={Logo} alt="Logo" /></Col>
                </Row>
                <Row>
                    <Col className="login-input">
                        <Input placeholder="username" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col className="login-input">
                        <Input placeholder="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col className="login-button">
                        <Button disabled={!email || !password} type="submit">Login</Button>
                    </Col>
                </Row>
            </Form>
        </Col >

    )
}

export default Login