import React from 'react'
import { useState } from 'react';
import { Row, Input, Col, Table, Button, InputGroup } from 'reactstrap'
import { useSiteContext } from '../context/SiteContext'
import db, { storage, ref, deleteObject, auth } from '../firebase'
import { MdSaveAlt } from 'react-icons/md'
import { MdOutlineDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/* Hi Baby ;) ! */

const Home = () => {

    const { t, i18n } = useTranslation();

    const { patternInfo, loginInfo, newdate, setPattern } = useSiteContext();

    const [patSearch, setPatSearch] = useState('');

    setPattern(undefined)



    if (loginInfo) {
        var from = loginInfo[0].periodEnd.split(".")
        var f = new Date(from[2], from[1] - 1, from[0])
        if (f < new Date()) {
            alert("Periyodunuz bitmiştir.")
            auth.signOut()
            loginInfo[0].userLevel = "-1";
            return;
        }
    }


    const remove_item_from_db = (id, url_) => {
        db.collection("pattern/" + JSON.parse(localStorage.getItem("user"))?.uid + "/" + JSON.parse(localStorage.getItem("user"))?.uid).doc(id).delete();
        remove_file_from_db(url_)
    }

    const remove_file_from_db = (url_) => {
        console.log(url_)
        deleteObject(ref(storage, url_));
    }

    if (patternInfo.length > 8) {
        db.collection("pattern/" + JSON.parse(localStorage.getItem("user"))?.uid + "/" + JSON.parse(localStorage.getItem("user"))?.uid).doc(patternInfo[patternInfo.length - 1].id).delete();
        deleteObject(ref(storage, patternInfo[patternInfo.length - 1].imageURL))
        return;
    }
    return (
        <>
            {loginInfo && loginInfo[0].userLevel === "-1" ? <div>Periyodunuz bitmiştir.</div> : <Row>
                <Row xs="1" className="search-bar">
                    <Col lg="6">
                        <InputGroup>
                            <Input
                                placeholder={t('description.searchPlaceHolder')}
                                type="search"
                                value={patSearch.toLowerCase()}
                                autoFocus="autoFocus"
                                onChange={(e) => setPatSearch(e.target.value)}
                            />
                        </InputGroup>
                    </Col>

                </Row>
                <Row className="justify-content-center">
                    {patternInfo.length === 0 ? <><Col xl="12" className='justify-content-center d-flex'>{t('description.emptyPattern')}</Col><Col xl="12" className='d-flex justify-content-center mt-3 home-create-template'><Link to={"/product"} className="cursor-button"><Button className="cursor-button">{t('description.createTemplate')}</Button></Link></Col></> : patternInfo.filter((pattern) => pattern.title.toLowerCase().includes(patSearch)).map((pattern, index) => (


                        <Col key={index} xl="5" xs="11" className="home-pattern">
                            <Row>
                                <Col className="home-pattern-title"><h4>{pattern.title}</h4></Col>
                                <Col className="home-pattern-cd">
                                    <p style={{ fontWeight: 600 }} >{t('description.createDate')}  {pattern.createDate} </p></Col>
                            </Row>
                            <Row>
                                <Col className="home-pattern-image"><img src={pattern.imageURL} width="150" /></Col>
                                <Col className="home-pattern-right">
                                    <Row className="home-pattern-sd">{t('description.campaingStartDate')} {pattern.startDate}</Row>
                                    <Row className="home-pattern-ed">{t('description.campaingEndDate')} {pattern.endDate}</Row>
                                    <Row className="home-pattern-icon">
                                        <Col>
                                            <MdOutlineDelete style={{ fontSize: "25", color: "red" }} onClick={() => remove_item_from_db(pattern.id, pattern.imageURL)} className='cursor-button' />
                                            <MdSaveAlt style={{ fontSize: "25", color: "green" }} onClick={() => window.open(pattern.imageURL, '_blank', 'noopener,noreferrer')} className='cursor-button' />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                    ))}


                </Row>
            </Row>}
        </>

    )
}

export default Home