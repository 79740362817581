import React from 'react'
import { Col, FormGroup, Label, Input, Row } from 'reactstrap'
import pattern1 from '../images/sablonlar/tekli/tekli-sablon-1.jpg'
import pattern2 from '../images/sablonlar/tekli/tekli-sablon-2.jpg'
import pattern3 from '../images/sablonlar/tekli/tekli-sablon-3.jpg'
import pattern4 from '../images/sablonlar/tekli/tekli-sablon-4.jpg'
import pattern5 from '../images/sablonlar/tekli/tekli-sablon-5.jpg'
import pattern6 from '../images/sablonlar/tekli/tekli-sablon-6.jpg'
import pattern7 from '../images/sablonlar/tekli/tekli-sablon-7.jpg'
import pattern8 from '../images/sablonlar/tekli/tekli-sablon-8.jpg'
import pattern9 from '../images/sablonlar/tekli/tekli-sablon-9.jpg'
import pattern10 from '../images/sablonlar/tekli/tekli-sablon-10.jpg'
import pattern11 from '../images/sablonlar/tekli/tekli-sablon-11.jpg'
import pattern12 from '../images/sablonlar/tekli/tekli-sablon-12.jpg'
import { useSiteContext } from '../context/SiteContext'

const PatternItemTekli = () => {
    const { pattern, setPattern } = useSiteContext();

    return (
        <Row>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern1} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts1"
                            onChange={() => setPattern('ts1')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern2} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts2"
                            onChange={() => setPattern('ts2')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern3} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts3"
                            onChange={() => setPattern('ts3')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern4} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts4"
                            onChange={() => setPattern('ts4')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern5} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts5"
                            onChange={() => setPattern('ts5')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern6} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts6"
                            onChange={() => setPattern('ts6')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern7} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts7"
                            onChange={() => setPattern('ts7')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern8} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts8"
                            onChange={() => setPattern('ts8')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern9} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts9"
                            onChange={() => setPattern('ts9')}
                        />
                    </Label>
                </FormGroup>
            </Col>

            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern10} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts10"
                            onChange={() => setPattern('ts10')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern11} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts11"
                            onChange={() => setPattern('ts11')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern12} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ts12"
                            onChange={() => setPattern('ts12')}
                        />
                    </Label>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default PatternItemTekli