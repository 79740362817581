import React from 'react'
import { Col, FormGroup, Label, Input, Row } from 'reactstrap'
import pattern1 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-1.jpg'
import pattern2 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-2.jpg'
import pattern3 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-3.jpg'
import pattern4 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-4.jpg'
import pattern5 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-5.jpg'
import pattern6 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-6.jpg'
import pattern7 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-7.jpg'
import pattern8 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-8.jpg'
import pattern9 from '../images/sablonlar/dokuzlu/dokuzlu-sablon-9.jpg'
import { useSiteContext } from '../context/SiteContext'

const PatternItemDokuzlu = () => {

    const { pattern, setPattern } = useSiteContext();

    return (
        <Row>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern1} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds1"
                            onChange={() => setPattern('ds1')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern2} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds2"
                            onChange={() => setPattern('ds2')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern3} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds3"
                            onChange={() => setPattern('ds3')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern4} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds4"
                            onChange={() => setPattern('ds4')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern5} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds5"
                            onChange={() => setPattern('ds5')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern6} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds6"
                            onChange={() => setPattern('ds6')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern7} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds7"
                            onChange={() => setPattern('ds7')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern8} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds8"
                            onChange={() => setPattern('ds8')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern9} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-ds9"
                            onChange={() => setPattern('ds9')}
                        />
                    </Label>
                </FormGroup>
            </Col>

        </Row>
    )
}

export default PatternItemDokuzlu