import React from 'react'
import { Col, Row } from 'reactstrap'
import { useSiteContext } from '../context/SiteContext'
import toast from 'react-hot-toast'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const CartProduct = ({ id, name, price, newPrice, imgURL }) => {
    const { cartUrunler_, setCartUrunler_, setCartLeng } = useSiteContext();

    const { t, i18n } = useTranslation();


    const remove_item = () => {

        setCartUrunler_(
            cartUrunler_.filter(c => c.id !== id)
        )
        toast.success(name + ` ${t('description.productRemoved')}`)

    }
    setCartLeng(cartUrunler_?.length);

    return (
        <Row className="cart-item">
            <Row>
                <Col xl="10" xs="10" className="cart-product-name">  {name}  </Col>
                <Col xl="1" xs="1" className="cart-delete-product-button" onClick={remove_item}>-</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col xl="4" xs="4" className="cart-product-image">
                    <img src={imgURL} alt="" height="50" />
                </Col>
                <Col xl="8" xs="8">
                    <Row className={classNames({
                        'cart-product-price': true,
                        'none': price === undefined
                    })}>{price}€</Row>
                    <Row className="cart-product-last-price">{newPrice}€</Row>
                </Col>
            </Row>
        </Row>
    )
}

export default CartProduct