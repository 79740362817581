import React from 'react'
import { Col, FormGroup, Label, Input, Row } from 'reactstrap'
import pattern1 from '../images/sablonlar/uclu/img_uclu_sablon1.jpg'
import pattern2 from '../images/sablonlar/uclu/img_uclu_sablon2.jpg'
import pattern3 from '../images/sablonlar/uclu/img_uclu_sablon3.jpg'
import pattern4 from '../images/sablonlar/uclu/img_uclu_sablon4.jpg'
import pattern5 from '../images/sablonlar/uclu/img_uclu_sablon5.jpg'
import pattern6 from '../images/sablonlar/uclu/img_uclu_sablon6.jpg'
import pattern7 from '../images/sablonlar/uclu/img_uclu_sablon7.jpg'
import pattern8 from '../images/sablonlar/uclu/img_uclu_sablon8.jpg'
import pattern9 from '../images/sablonlar/uclu/img_uclu_sablon9.jpg'
import pattern10 from '../images/sablonlar/uclu/img_uclu_sablon10.jpg'
import pattern11 from '../images/sablonlar/uclu/img_uclu_sablon11.jpg'
import pattern12 from '../images/sablonlar/uclu/img_uclu_sablon12.jpg'
import { useSiteContext } from '../context/SiteContext'
const PatternItemUclu = () => {

    const { pattern, setPattern } = useSiteContext();

    return (
        <Row>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern1} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us1')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern2} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us2')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern3} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us3')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern4} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us4')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern5} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us5')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern6} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us6')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern7} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us7')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern8} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us8')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern9} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us1"
                            onChange={() => setPattern('us9')}
                        />
                    </Label>
                </FormGroup>
            </Col >

            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern10} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us10"
                            onChange={() => setPattern('us10')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern11} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us11"
                            onChange={() => setPattern('us11')}
                        />
                    </Label>
                </FormGroup>
            </Col >
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern12} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-us12"
                            onChange={() => setPattern('us12')}
                        />
                    </Label>
                </FormGroup>
            </Col >
        </Row>

    )
}

export default PatternItemUclu