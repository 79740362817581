import React, { useContext, useEffect } from 'react'
import { Row, Button, Col } from 'reactstrap'
import CartProduct from './CartProduct'
import { Link } from 'react-router-dom'
import close from '../images/close.png'
import SiteContext, { useSiteContext } from '../context/SiteContext'
import { useTranslation } from 'react-i18next'


const ProductCart = ({ }) => {

    const { cartUrunler_, toCart, setToCart, cartLeng } = useSiteContext();

    const { t, i18n } = useTranslation();

    const openList = () => {
        document.getElementById("cartList").style.height = "80%";
        document.getElementById("backgroundMask").style.height = "100%";
    }
    const closeList = () => {
        document.getElementById("cartList").style.height = "0";
        document.getElementById("backgroundMask").style.height = "0%";
    }


    return (
        <Row className={["product-cart"].join(' ')}>
            <Row onClick={openList}>
                <Col xs="1">{cartUrunler_ && cartUrunler_.length}</Col>
                <Col xs="10" className='cursor-button'>{t('description.productList')}</Col>
            </Row>
            <Row onClick={closeList} id="backgroundMask" className="background-mask"></Row>
            <Row id="cartList" className="cart-list">

                <img onClick={closeList} src={close} alt="close" className="close cursor-button" />
                <Row className="cart">
                    {cartUrunler_ &&
                        cartUrunler_.map(({ id, name, price, newPrice, unit, weight, imgURL, index, title }) => (
                            <CartProduct id={id} name={title} price={price} newPrice={newPrice} unit={unit} weight={weight} imgURL={imgURL} />
                        ))}

                </Row>
                <Row className="mobile-cart-button" >

                    {cartLeng == 1 || cartLeng == 3 || cartLeng == 6 ?
                        <Link to={"/pattern"} className="p-0" style={{ color: "#fff", textDecoration: "none" }}>
                            <Button style={{ width: '100%', backgroundColor: "#7a17bf" }}>


                                {t('description.createTemplate')}

                            </Button>
                        </Link> : <Button style={{ color: "#fff", textDecoration: "none", backgroundColor: "#7a17bf" }}>{t('description.pcsNote')}</Button>

                    }



                </Row>
            </Row >
        </Row>
    )
}

export default ProductCart