import React from 'react'
import { Col, FormGroup, Label, Input, Row } from 'reactstrap'
import pattern1 from '../images/sablonlar/altili/altili-sablon-1.jpg'
import pattern2 from '../images/sablonlar/altili/altili-sablon-2.jpg'
import pattern3 from '../images/sablonlar/altili/altili-sablon-3.jpg'
import pattern4 from '../images/sablonlar/altili/altili-sablon-4.jpg'
import pattern5 from '../images/sablonlar/altili/altili-sablon-5.jpg'
import pattern6 from '../images/sablonlar/altili/altili-sablon-6.jpg'
import pattern7 from '../images/sablonlar/altili/altili-sablon-7.jpg'
import pattern8 from '../images/sablonlar/altili/altili-sablon-8.jpg'
import pattern9 from '../images/sablonlar/altili/altili-sablon-9.jpg'
import pattern10 from '../images/sablonlar/altili/altili-sablon-10.jpg'
import pattern11 from '../images/sablonlar/altili/altili-sablon-11.jpg'
import pattern12 from '../images/sablonlar/altili/altili-sablon-12.jpg'
import { useSiteContext } from '../context/SiteContext'

const PatternItemAltili = () => {

    const { pattern, setPattern } = useSiteContext();

    return (
        <Row>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern1} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as1"
                            onChange={() => setPattern('as1')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern2} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as2"
                            onChange={() => setPattern('as2')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern3} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as3"
                            onChange={() => setPattern('as3')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern4} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as4"
                            onChange={() => setPattern('as4')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern5} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as5"
                            onChange={() => setPattern('as5')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern6} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as6"
                            onChange={() => setPattern('as6')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern7} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as7"
                            onChange={() => setPattern('as7')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern8} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as8"
                            onChange={() => setPattern('as8')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern9} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as9"
                            onChange={() => setPattern('as9')}
                        />
                    </Label>
                </FormGroup>
            </Col>

            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern10} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as10"
                            onChange={() => setPattern('as10')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern11} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as11"
                            onChange={() => setPattern('as11')}
                        />
                    </Label>
                </FormGroup>
            </Col>
            <Col className="pattern-item">
                <FormGroup >
                    <Label >
                        <div className="pattern-item-mask"></div>
                        <img src={pattern12} alt="Pattern" className="pattern-img" />
                        <Input
                            name="radio1"
                            type="radio"
                            className="pattern-radio"
                            value="pattern-as12"
                            onChange={() => setPattern('as12')}
                        />
                    </Label>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default PatternItemAltili