import React, { useState, useContext, useEffect } from 'react'
import { useSiteContext } from '../context/SiteContext'
import { Col, Form, Input, Label, Row, InputGroup, FormGroup, Button } from 'reactstrap'
import toast from 'react-hot-toast'
import db, { storage } from '../firebase'
import { nanoid } from 'nanoid'
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage'
import Loader from './Loader';
import Switch from 'react-switch';
import { MdCloudUpload, MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'



const NewProduct = () => {

  const [productName, setProductName] = useState()
  const [productNameTr, setProductNameTr] = useState()
  const [productCategory, setProductCategory] = useState("0")
  const [productImgURL, setProductImgURL] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [imageAsset, setImageAsset] = useState(null);
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [i_newCategory, setI_newCategory] = useState();
  const [swCheck, setCheck] = useState(false);

  const radioHandle = () => {
    setCheck(swCheck === true ? false : true)
  }
  const { categoryInfo, loginInfo } = useSiteContext();

  const { t, i18n } = useTranslation();


  const addProduct = (e) => {

    if (productName === undefined || productName === "") {
      toast.error(`${t('description.emptyProductName')}`);
      return;
    } else if (productCategory === "0") {
      toast.error(`${t('description.emptyCategory')}`);
      return;
    } else if (productNameTr === undefined || productNameTr === "") {
      toast.error(`${t('description.emptyProductName')} TR`);
      return;
    } else {
      db.collection("product").add({
        id: nanoid(),
        name: productName,
        trName: productNameTr,
        category: productCategory,
        imgURL: productImgURL,
        textLength: productName.length,
      })
      toast.success(`${t('description.newProductAdded')}`);
      setProductCategory("0")
      setProductName("")
      setImageAsset("")
      console.log(productCategory)

    }

  }


  const uploadImage = (e) => {
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(storage, `products/${Date.now()}-${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on('state_changed', (snapshot) => {
      const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    }, (error) => {
      console.log(error);
      setFields(true);
      setMsg('Error while uploading : Try Again🙇‍♂️');
      setAlertStatus('danger');
      setTimeout(() => {
        setFields(false);
        setIsLoading(false);
      }, 4000)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        setImageAsset(downloadURL);
        setProductImgURL(downloadURL);
        setIsLoading(false);
        setFields(true);
        toast.success('Image uploaded succesfully😎');
        setAlertStatus("success");
        setTimeout(() => {
          setFields(false);
        }, 4000);

      })
    });
  }
  const deleteImage = () => {
    setIsLoading(true);
    const deleteRef = ref(storage, imageAsset);
    deleteObject(deleteRef).then(() => {
      setImageAsset(null);
      setIsLoading(false);
      setFields(true);
      setMsg('Image deleted succesfully🚮🗑');
      setTimeout(() => {
        setFields(false);
      }, 4000);
    })
  }

  return (
    <Row>
      {loginInfo && loginInfo[0].userLevel === "0" ? <div className="">
        <div >
          <Form>
            <Row >
              <Col xl="5" md="8" sm="12">
                <div className='cursor-button image-upload'>
                  {isLoading ? <Loader /> : (<>
                    {!imageAsset ? (<>
                      <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
                        <div className='w-full h-full flex flex-col items-center justify-center cursor-pointer gap-2'>
                          <MdCloudUpload className='text-gray-500 text-3xl hover:text-gray-700' />
                          <p className='text-gray-500 text-xl'>{t('description.uploadImage')}</p>
                        </div>
                        <input type="file" name="uploadimage" accept='image/*' onChange={uploadImage} className='w-0 h-0 ' />
                      </label>
                    </>) :
                      (<> <div className='relative h-20 '>
                        <img src={imageAsset} alt="uploaded image" style={{ width: 200 }} />
                        <button type='button' className='absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all' onClick={deleteImage}>
                          <MdDelete className='text-white' />
                        </button>
                      </div>
                      </>)}
                  </>)}
                </div>
              </Col>
            </Row>
            <Row>

              <Col>
                <Label>{t('description.name')}</Label>
                <Input placeholder={t('description.name')} type='text' id='new-name' name='new-name' value={productName} onChange={(e) => setProductName(e.target.value)} />
              </Col>
              <Col>
                <Label>{t('description.name')} TR</Label>
                <Input placeholder={t('description.name')} type='text' id='new-name' name='new-name' value={productNameTr} onChange={(e) => setProductNameTr(e.target.value)} />
              </Col>
            </Row>
            <Row className="mt-2">
            </Row>
            <Row>
            <Col className="mt-2">
              </Col>
              <Col>
              </Col>
             
              <FormGroup>
                <Label for="exampleSelect">
                  {t('description.category')}
                </Label>
                <InputGroup>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={productCategory}
                    onChange={(e) => setProductCategory(e.target.value)}
                  >
                    <option value={"0"}>
                      {t('description.selectCategory')}
                    </option>
                    {categoryInfo && categoryInfo.filter((category) => category.id != "all" ).map(category => (
                      <option value={category.id}>
                        {t('description.' + category.id)}
                      </option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Row>
            <Row>
              <Col>
                <Button
                  color="success"
                  outline
                  className="float-end"
                  onClick={addProduct}
                >
                  {t('description.add')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div> : <div>Accsess Denied</div>}
    </Row>
  )
}

export default NewProduct