import firebase from 'firebase/compat/app';
import { getStorage, deleteObject, ref } from "firebase/storage";

import { initializeApp } from '@firebase/app';

import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, updatePassword, signInWithEmailAndPassword, signOut } from "firebase/auth"

import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import store from './store';
import { login as loginHandle, logout as logoutHandle } from './store/auth';
import toast from 'react-hot-toast';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA8nKM12RK3bSHyZWOK6mm_DINR5NZxgTk",
  authDomain: "campaing-demo-78a58.firebaseapp.com",
  projectId: "campaing-demo-78a58",
  storageBucket: "campaing-demo-78a58.appspot.com",
  messagingSenderId: "666616187076",
  appId: "1:666616187076:web:371d22213b32b2c5696191"
};

// Initialize Firebase

const secondApp = initializeApp(firebaseConfig);

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const auth = firebase.auth();

const secondAuth = getAuth();

const provider = new firebase.auth.GoogleAuthProvider();

export const register = async (email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(secondAuth, email, password)
    return
  }
  catch (error) {
    toast.error(error.message)
  }
}

export const login = async (email, password) => {
  try {
    const user = await signInWithEmailAndPassword(secondAuth, email, password)
    return user
  }
  catch (error) {
    toast.error(error.message)
  }
}

export const logout = async () => {
  try {
    await signOut(secondAuth)
    return true
  }
  catch (error) {
    toast.error(error.message)
  }
}

export const resetPassword = async password => {
  try {
    await updatePassword(auth.currentUser, password)
    toast.success('Your Password Has Been Updated')
    return true
  } catch (error) {
    toast.error(error.message)
  }
}

onAuthStateChanged(secondAuth, (user) => {
  if (user) {
    store.dispatch(loginHandle(user))
  } else {
    store.dispatch(logoutHandle())
  }
})


const storage = getStorage(firebaseApp);
export default db;
export { auth, provider, storage, ref, deleteObject };

