import React from 'react'
import { Col, Form, Input, Label, Row, FormGroup, Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useSiteContext } from '../context/SiteContext'
import html2canvas from 'html2canvas'
import canvas2image from 'canvas2image-2'
import { useEffect } from 'react';
import { nanoid } from 'nanoid'
import db from '../firebase'
import PatternItemUclu from './PatternItemUclu'
import PatternItemDokuzlu from './PatternItemDokuzlu'
import PatternItemAltili from './PatternItemAltili'
import PatternItemTekli from './PatternItemTekli'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { storage } from '../firebase'
import Logo from '../images/logo.png'
import toast from 'react-hot-toast'
import DomToImage from 'dom-to-image'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'

const Pattern = () => {
  const { cartUrunler_, setCartUrunler_, patternInfo } = useSiteContext()

  const { t, i18n } = useTranslation();

  let a
  let unitkg



  const navigate = useNavigate();

  const { loginInfo, setPattern, pattern, profileInfo, patternBox, setPatternBox, i_campTitle, setI_campTitle, i_startDate, setI_startDate, i_endDate, setI_endDate, cartLeng, oldDate, newDate, newYear_, newMonth_, newDay_,
    newdate } = useSiteContext();




  const giveMeSS = (capture) => {
    if (i_campTitle === null || i_campTitle === undefined) {
      toast.error(`${t('description.campaingTitleEmpty')}`);
      return;
    } else if (i_startDate === null || i_startDate === undefined || i_endDate === undefined || i_endDate === null) {
      toast.error(`${t('description.campaingDateEmpty')}`)
      return;
    } else if (i_startDate > i_endDate) {
      toast.error(`${t('description.campaingDateWrong')}`);
      return;
    } else {
      // DomToImage.toPng(document.getElementById('capture'), {width:1080, height:1080}).then(function(blob) {
      //   debugger;
      //   var img = new Image();
      //   // img.src = blob;
      //   blob = dataURLtoFile(blob, new Date().toISOString() + '.jpg');
      //   const storageRef = ref(storage, `/files/` + JSON.parse(localStorage.getItem("user"))?.uid + `/${blob.name}`)
      //   const uploadTask = uploadBytesResumable(storageRef, blob);
      //   uploadTask.on("complete", () => {
      //     debugger;
      //     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
      //       debugger;
      //       window.open(url, '_blank', 'noopener,noreferrer')
      //       addPatternInfo(url)
      //     });
      //   })
      //   // saveAs(blob, 'myimage.png')
      // })


      html2canvas(document.getElementById(capture),
        {
          useCORS: true,
          onrendered: function (canvas) { },
          scale: 2
        }
      ).then(canvas => {
        // document.body.appendChild( canvas, 1080, 1080 );
        canvas2image.saveAsPNG(canvas, 1000, 1000);
        let file = canvas2image.convertToJPEG(canvas, 1000, 1000);
        file = file.src
        file = dataURLtoFile(file, new Date().toISOString() + '.jpg');
        const storageRef = ref(storage, `/files/` + JSON.parse(localStorage.getItem("user"))?.uid + `/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on("complete", () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // window.open(url, '_blank', 'noopener,noreferrer')
            addPatternInfo(url)
          });

        })
      });

      setPatternBox(
        patternBox => [...patternBox, { CampTitle: i_campTitle, StartDate: i_startDate, EndDate: i_endDate }]
      )


    }

    setCartUrunler_([]);
    navigate("/", { replace: true })


  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }


    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {

  }, [patternBox]);
  useEffect(() => {

    console.log(patternBox)
  }, [patternInfo]);



  const addPatternInfo = (url) => {
    db.collection("pattern/" + JSON.parse(localStorage.getItem("user"))?.uid + "/" + JSON.parse(localStorage.getItem("user"))?.uid).add({
      id: nanoid(),
      title: i_campTitle,
      startDate: startDate_,
      endDate: endDate_,
      createDate: newdate,
      imageURL: url,
      createDateOrder: new Date().toISOString()
    })
  }
  const calculatePrice = (weight, unit, price) => {
    if (unit === "kg") {
      unitkg = "kg";
      a = (parseFloat(price) / parseFloat(weight)).toFixed(2)
    }
    else if (unit === "gr") {
      unitkg = "kg";
      if (parseFloat(weight) <= 1000) {
        a = (parseFloat(price) * (1000 / parseFloat(weight))).toFixed(2)
      }
      else {
        a = (parseFloat(price) / (parseFloat(weight) / 1000)).toFixed(2)
      }
    }
    else if (unit === "lt") {
      unitkg = "lt";
      a = (parseFloat(price) / parseFloat(weight)).toFixed(2)
    }
    else {
      unitkg = "lt";
      if (parseFloat(weight) <= 1000) {
        a = (parseFloat(price) * (1000 / parseFloat(weight))).toFixed(2)
      }
      else {
        a = (parseFloat(price) / (parseFloat(weight) / 1000)).toFixed(2)
      }
    }
  }

  const navi = () => {
    navigate(-1, { replace: true })
  }

  const year = i_startDate?.split("-")[0]
  const month = i_startDate?.split("-")[1]
  const day = i_startDate?.split("-")[2]
  const year_ = i_endDate?.split("-")[0]
  const month_ = i_endDate?.split("-")[1]
  const day_ = i_endDate?.split("-")[2]
  const startDate_ = day + "." + month + "." + year;
  const endDate_ = day_ + "." + month_ + "." + year_;


  return (
    <>
      {/* Desktop Pattern Start */}
      <Row className="pattern-body hide-mobile" id="pattern-body">
        <Col xl="8" lg="8" sm="12" md="12" className="pattern-canvas" id="pattern-canvas">
          {pattern === undefined ? <div>{t('description.choosePattern')}</div> : <div id='capture' className={classNames({
            'sablon_genel img hide-mobile': true,
            'img_tekli_sablon1': pattern === 'ts1',
            'img_tekli_sablon2': pattern === 'ts2',
            'img_tekli_sablon3': pattern === 'ts3',
            'img_tekli_sablon4': pattern === 'ts4',
            'img_tekli_sablon5': pattern === 'ts5',
            'img_tekli_sablon6': pattern === 'ts6',
            'img_tekli_sablon7': pattern === 'ts7',
            'img_tekli_sablon8': pattern === 'ts8',
            'img_tekli_sablon9': pattern === 'ts9',
            'img_tekli_sablon10': pattern === 'ts10',
            'img_tekli_sablon11': pattern === 'ts11',
            'img_tekli_sablon12': pattern === 'ts12',
            'img_uclu_sablon1': pattern === 'us1',
            'img_uclu_sablon2': pattern === 'us2',
            'img_uclu_sablon3': pattern === 'us3',
            'img_uclu_sablon4': pattern === 'us4',
            'img_uclu_sablon5': pattern === 'us5',
            'img_uclu_sablon6': pattern === 'us6',
            'img_uclu_sablon7': pattern === 'us7',
            'img_uclu_sablon8': pattern === 'us8',
            'img_uclu_sablon9': pattern === 'us9',
            'img_uclu_sablon10': pattern === 'us10',
            'img_uclu_sablon11': pattern === 'us11',
            'img_uclu_sablon12': pattern === 'us12',
            'img_altili_sablon1': pattern === 'as1',
            'img_altili_sablon2': pattern === 'as2',
            'img_altili_sablon3': pattern === 'as3',
            'img_altili_sablon4': pattern === 'as4',
            'img_altili_sablon5': pattern === 'as5',
            'img_altili_sablon6': pattern === 'as6',
            'img_altili_sablon7': pattern === 'as7',
            'img_altili_sablon8': pattern === 'as8',
            'img_altili_sablon9': pattern === 'as9',
            'img_altili_sablon10': pattern === 'as10',
            'img_altili_sablon11': pattern === 'as11',
            'img_altili_sablon12': pattern === 'as12',
            'img_dokuzlu_sablon1': pattern === 'ds1',
            'img_dokuzlu_sablon2': pattern === 'ds2',
            'img_dokuzlu_sablon3': pattern === 'ds3',
            'img_dokuzlu_sablon4': pattern === 'ds4',
            'img_dokuzlu_sablon5': pattern === 'ds5',
            'img_dokuzlu_sablon6': pattern === 'ds6',
            'img_dokuzlu_sablon7': pattern === 'ds7',
            'img_dokuzlu_sablon8': pattern === 'ds8',
            'img_dokuzlu_sablon9': pattern === 'ds9',
          })}>
            {cartLeng === 1 ?
              <div className={classNames({
                'old-price': true,
                'none': cartUrunler_[0].price === undefined
              })} >
                {cartUrunler_[0].price}€
              </div> : null}
            {cartLeng === 3 ?
              <div>
                <div className={classNames({
                  'old-price3-1': true,
                  'none': cartUrunler_[0].price === undefined
                })}>
                  {cartUrunler_[0].price}€
                </div>
                <div className={classNames({
                  'old-price3-2': true,
                  'none': cartUrunler_[1].price === undefined
                })}>
                  {cartUrunler_[1].price}€
                </div>
                <div className={classNames({
                  'old-price3-3': true,
                  'none': cartUrunler_[2].price === undefined
                })} >
                  {cartUrunler_[2].price}€
                </div>
              </div>
              : null}
            {cartLeng === 6 ?
              <div>
                <div className={classNames({
                  'old-price6-1': true,
                  'none': cartUrunler_[0].price === undefined
                })} >
                  {cartUrunler_[0].price}€
                </div>
                <div className={classNames({
                  'old-price6-2': true,
                  'none': cartUrunler_[1].price === undefined
                })} >
                  {cartUrunler_[1].price}€
                </div>
                <div className={classNames({
                  'old-price6-3': true,
                  'none': cartUrunler_[2].price === undefined
                })} >
                  {cartUrunler_[2].price}€
                </div>
                <div className={classNames({
                  'old-price6-4': true,
                  'none': cartUrunler_[3].price === undefined
                })} >
                  {cartUrunler_[3].price}€
                </div>
                <div className={classNames({
                  'old-price6-5': true,
                  'none': cartUrunler_[4].price === undefined
                })} >
                  {cartUrunler_[4].price}€
                </div>
                <div className={classNames({
                  'old-price6-6': true,
                  'none': cartUrunler_[5].price === undefined
                })} >
                  {cartUrunler_[5].price}€
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className={classNames({
                  'old-price9-1': true,
                  'none': cartUrunler_[0].price === undefined
                })} >
                  {cartUrunler_[0].price}€
                </div>
                <div className={classNames({
                  'old-price9-2': true,
                  'none': cartUrunler_[1].price === undefined
                })} >
                  {cartUrunler_[1].price}€
                </div>
                <div className={classNames({
                  'old-price9-3': true,
                  'none': cartUrunler_[2].price === undefined
                })} >
                  {cartUrunler_[2].price}€
                </div>
                <div className={classNames({
                  'old-price9-4': true,
                  'none': cartUrunler_[3].price === undefined
                })} >
                  {cartUrunler_[3].price}€
                </div>
                <div className={classNames({
                  'old-price9-5': true,
                  'none': cartUrunler_[4].price === undefined
                })} >
                  {cartUrunler_[4].price}€
                </div>
                <div className={classNames({
                  'old-price9-6': true,
                  'none': cartUrunler_[5].price === undefined
                })} >
                  {cartUrunler_[5].price}€
                </div>
                <div className={classNames({
                  'old-price9-7': true,
                  'none': cartUrunler_[6].price === undefined
                })} >
                  {cartUrunler_[6].price}€
                </div>
                <div className={classNames({
                  'old-price9-8': true,
                  'none': cartUrunler_[7].price === undefined
                })} >
                  {cartUrunler_[7].price}€
                </div>
                <div className={classNames({
                  'old-price9-9': true,
                  'none': cartUrunler_[8].price === undefined
                })} >
                  {cartUrunler_[8].price}€
                </div> </div> : null}
            {cartLeng === 1 ? <div className="new-price" >
              {cartUrunler_[0].newPrice}€
            </div> : null}
            {cartLeng === 3 ?
              <div>
                <div className="new-price3-1" >
                  {cartUrunler_[0].newPrice}€
                </div>
                <div className="new-price3-2" >
                  {cartUrunler_[1].newPrice}€
                </div>
                <div className="new-price3-3" >
                  {cartUrunler_[2].newPrice}€
                </div>
              </div>
              : null}
            {cartLeng === 6 ?
              <div>
                <div className="new-price6-1" >
                  {cartUrunler_[0].newPrice}€
                </div>
                <div className="new-price6-2" >
                  {cartUrunler_[1].newPrice}€
                </div>
                <div className="new-price6-3" >
                  {cartUrunler_[2].newPrice}€
                </div>
                <div className="new-price6-4" >
                  {cartUrunler_[3].newPrice}€
                </div>
                <div className="new-price6-5" >
                  {cartUrunler_[4].newPrice}€
                </div>
                <div className="new-price6-6" >
                  {cartUrunler_[5].newPrice}€
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className="new-price9-1" >
                  {cartUrunler_[0].newPrice}€
                </div>
                <div className="new-price9-2" >
                  {cartUrunler_[1].newPrice}€
                </div>
                <div className="new-price9-3" >
                  {cartUrunler_[2].newPrice}€
                </div>
                <div className="new-price9-4" >
                  {cartUrunler_[3].newPrice}€
                </div>
                <div className="new-price9-5" >
                  {cartUrunler_[4].newPrice}€
                </div>
                <div className="new-price9-6" >
                  {cartUrunler_[5].newPrice}€
                </div>
                <div className="new-price9-7" >
                  {cartUrunler_[6].newPrice}€
                </div>
                <div className="new-price9-8" >
                  {cartUrunler_[7].newPrice}€
                </div>
                <div className="new-price9-9" >
                  {cartUrunler_[8].newPrice}€
                </div> </div> : null}
            {cartLeng === 1 ? <div className={classNames({
              "title": true,
              "fs-19": cartUrunler_[0].title.length > 16,
            })} >
              {cartUrunler_[0].title}
            </div> : null}
            {cartLeng === 3 ?
              <div>
                <div className={classNames({
                  "title3-1": true,
                  "fs-16": cartUrunler_[0].title.length > 16
                })}>
                  {cartUrunler_[0].title}
                </div>
                <div className={classNames({
                  "title3-2": true,
                  "fs-16": cartUrunler_[1].title.length > 16
                })}>
                  {cartUrunler_[1].title}
                </div>
                <div className={classNames({
                  "title3-3": true,
                  "fs-16": cartUrunler_[2].title.length > 16
                })}>
                  {cartUrunler_[2].title}
                </div>
              </div>
              : null}
            {cartLeng === 6 ?
              <div>
                <div className={classNames({
                  "title6-1": true,
                  "fs-12": cartUrunler_[0].title.length > 16
                })}>
                  {cartUrunler_[0].title}
                </div>
                <div className={classNames({
                  "title6-2": true,
                  "fs-12": cartUrunler_[1].title.length > 16
                })}>
                  {cartUrunler_[1].title}
                </div>
                <div className={classNames({
                  "title6-3": true,
                  "fs-12": cartUrunler_[2].title.length > 16
                })}>
                  {cartUrunler_[2].title}
                </div>
                <div className={classNames({
                  "title6-4": true,
                  "fs-12": cartUrunler_[3].title.length > 16
                })}>
                  {cartUrunler_[3].title}
                </div>
                <div className={classNames({
                  "title6-5": true,
                  "fs-12": cartUrunler_[4].title.length > 16
                })}>
                  {cartUrunler_[4].title}
                </div>
                <div className={classNames({
                  "title6-6": true,
                  "fs-12": cartUrunler_[5].title.length > 16
                })}>
                  {cartUrunler_[5].title}
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className="title9-1">
                  {cartUrunler_[0].title}
                </div>
                <div className="title9-2">
                  {cartUrunler_[1].title}
                </div>
                <div className="title9-3">
                  {cartUrunler_[2].title}
                </div>
                <div className="title9-4">
                  {cartUrunler_[3].title}
                </div>
                <div className="title9-5">
                  {cartUrunler_[4].title}
                </div>
                <div className="title9-6">
                  {cartUrunler_[5].title}
                </div>
                <div className="title9-7">
                  {cartUrunler_[6].title}
                </div>
                <div className="title9-8">
                  {cartUrunler_[7].title}
                </div>
                <div className="title9-9">
                  {cartUrunler_[8].title}
                </div>
              </div>
              : null}
            {cartLeng === 1 ? <div className={classNames({
              "titleTR": true,
              "fs-16": cartUrunler_[0].titleTR.length > 16,
            })}>
              {cartUrunler_[0].titleTR}
            </div> : null}
            {cartLeng === 3 ?
              <div>
                <div className={classNames({
                  "titleTR3-1": true,
                  "fs-16": cartUrunler_[0].titleTR.length > 16,
                })}>
                  {cartUrunler_[0].titleTR}
                </div>
                <div className={classNames({
                  "titleTR3-2": true,
                  "fs-16": cartUrunler_[1].titleTR.length > 16,
                })}>
                  {cartUrunler_[1].titleTR}
                </div>
                <div className={classNames({
                  "titleTR3-3": true,
                  "fs-16": cartUrunler_[2].titleTR.length > 16,
                })}>
                  {cartUrunler_[2].titleTR}
                </div>
              </div>
              : null}
            {cartLeng === 6 ?
              <div>
                <div className={classNames({
                  "titleTR6-1": true,
                  "fs-10": cartUrunler_[0].titleTR.length > 16,
                })}>
                  {cartUrunler_[0].titleTR}
                </div>
                <div className={classNames({
                  "titleTR6-2": true,
                  "fs-10": cartUrunler_[1].titleTR.length > 16,
                })}>
                  {cartUrunler_[1].titleTR}
                </div>
                <div className={classNames({
                  "titleTR6-3": true,
                  "fs-10": cartUrunler_[2].titleTR.length > 16,
                })}>
                  {cartUrunler_[2].titleTR}
                </div>
                <div className={classNames({
                  "titleTR6-4": true,
                  "fs-10": cartUrunler_[3].titleTR.length > 16,
                })}>
                  {cartUrunler_[3].titleTR}
                </div>
                <div className={classNames({
                  "titleTR6-5": true,
                  "fs-10": cartUrunler_[4].titleTR.length > 16,
                })}>
                  {cartUrunler_[4].titleTR}
                </div>
                <div className={classNames({
                  "titleTR6-6": true,
                  "fs-10": cartUrunler_[5].titleTR.length > 16,
                })}>
                  {cartUrunler_[5].titleTR}
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className="titleTR9-1">
                  {cartUrunler_[0].titleTR}
                </div>
                <div className="titleTR9-2">
                  {cartUrunler_[1].titleTR}
                </div>
                <div className="titleTR9-3">
                  {cartUrunler_[2].titleTR}
                </div>
                <div className="titleTR9-4">
                  {cartUrunler_[3].titleTR}
                </div>
                <div className="titleTR9-5">
                  {cartUrunler_[4].titleTR}
                </div>
                <div className="titleTR9-6">
                  {cartUrunler_[5].titleTR}
                </div>
                <div className="titleTR9-7">
                  {cartUrunler_[6].titleTR}
                </div>
                <div className="titleTR9-8">
                  {cartUrunler_[7].titleTR}
                </div>
                <div className="titleTR9-9">
                  {cartUrunler_[8].titleTR}
                </div>
              </div>
              : null}
            {cartLeng === 1 ? <div className={classNames({
              'origin': true,
              'none': cartUrunler_[0].origin === undefined
            })}>
              {cartUrunler_[0].origin}
            </div> : null}
            {cartLeng === 3 ?
              <div>
                <div className={classNames({
                  'origin3-1': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[0].origin}
                </div>
                <div className={classNames({
                  'origin3-2': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[1].origin}
                </div>
                <div className={classNames({
                  'origin3-3': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[2].origin}
                </div>
              </div>
              : null}
            {cartLeng === 6 ?
              <div>
                <div className={classNames({
                  'origin6-1': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[0].origin}
                </div>
                <div className={classNames({
                  'origin6-2': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[1].origin}
                </div>
                <div className={classNames({
                  'origin6-3': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[2].origin}
                </div>
                <div className={classNames({
                  'origin6-4': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[3].origin}
                </div>
                <div className={classNames({
                  'origin6-5': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[4].origin}
                </div>
                <div className={classNames({
                  'origin6-6': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[5].origin}
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className={classNames({
                  'origin9-1': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[0].origin}
                </div>
                <div className={classNames({
                  'origin9-2': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[1].origin}
                </div>
                <div className={classNames({
                  'origin9-3': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[2].origin}
                </div>
                <div className={classNames({
                  'origin9-4': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[3].origin}
                </div>
                <div className={classNames({
                  'origin9-5': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[4].origin}
                </div>
                <div className={classNames({
                  'origin9-6': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[5].origin}
                </div>
                <div className={classNames({
                  'origin9-7': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[6].origin}
                </div>
                <div className={classNames({
                  'origin9-8': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[7].origin}
                </div>
                <div className={classNames({
                  'origin9-9': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[8].origin}
                </div>
              </div>
              : null}

            {cartLeng === 1 ? <div className="weight">
              {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
              {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
            </div> : null}
            {cartLeng === 3 ?
              <div>
                <div className="weight3-1">
                  {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                  {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight3-2">
                  {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                  {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null}   {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight3-3">
                  {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                  {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}   {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                </div>
              </div>
              : null}
            {cartLeng === 6 ?
              <div>
                <div className="weight6-1">
                  {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                  {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight6-2">
                  {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                  {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight6-3">
                  {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                  {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight6-4">
                  {calculatePrice(cartUrunler_[3].weight, cartUrunler_[3].unit, cartUrunler_[3].newPrice)}
                  {cartUrunler_[3].pcs ? <>({cartUrunler_[3].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[3].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight6-5">
                  {calculatePrice(cartUrunler_[4].weight, cartUrunler_[4].unit, cartUrunler_[4].newPrice)}
                  {cartUrunler_[4].pcs ? <>({cartUrunler_[4].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[4].weight} {cartUrunler_[4].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight6-6">
                  {calculatePrice(cartUrunler_[5].weight, cartUrunler_[5].unit, cartUrunler_[5].newPrice)}
                  {cartUrunler_[5].pcs ? <>({cartUrunler_[5].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[5].weight} {cartUrunler_[5].unit} | 1 {unitkg} = {a}€
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className="weight9-1">
                  {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                  {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-2">
                  {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                  {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-3">
                  {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                  {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-4">
                  {calculatePrice(cartUrunler_[3].weight, cartUrunler_[3].unit, cartUrunler_[3].newPrice)}
                  {cartUrunler_[3].pcs ? <>({cartUrunler_[3].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[3].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-5">
                  {calculatePrice(cartUrunler_[4].weight, cartUrunler_[4].unit, cartUrunler_[4].newPrice)}
                  {cartUrunler_[4].pcs ? <>({cartUrunler_[4].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[4].weight} {cartUrunler_[4].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-6">
                  {calculatePrice(cartUrunler_[5].weight, cartUrunler_[5].unit, cartUrunler_[5].newPrice)}
                  {cartUrunler_[5].pcs ? <>({cartUrunler_[5].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[5].weight} {cartUrunler_[5].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-7">
                  {calculatePrice(cartUrunler_[6].weight, cartUrunler_[6].unit, cartUrunler_[6].newPrice)}
                  {cartUrunler_[6].pcs ? <>({cartUrunler_[6].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[6].weight} {cartUrunler_[6].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-8">
                  {calculatePrice(cartUrunler_[7].weight, cartUrunler_[7].unit, cartUrunler_[7].newPrice)}
                  {cartUrunler_[7].pcs ? <>({cartUrunler_[7].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[7].weight} {cartUrunler_[7].unit} | 1 {unitkg} = {a}€
                </div>
                <div className="weight9-9">
                  {calculatePrice(cartUrunler_[8].weight, cartUrunler_[8].unit, cartUrunler_[8].newPrice)}
                  {cartUrunler_[8].pcs ? <>({cartUrunler_[8].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[8].weight} {cartUrunler_[8].unit} | 1 {unitkg} = {a}€
                </div>
              </div>
              : null}
            <div className="pattern-logo">
              <img src={loginInfo && loginInfo[0].logoURL === "" ? Logo : loginInfo[0].logoURL} alt="Logo" width="150px" />
            </div>
            {cartLeng === 1 ?
              <div className="product-image">
                <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image" />
              </div>
              : null}
            {cartLeng === 3 ?
              <div>
                <div className="product-image3-1">
                  <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image3-1" />
                </div>
                <div className="product-image3-2">
                  <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image3-2" />
                </div>
                <div className="product-image3-3">
                  <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image3-3" />
                </div>
              </div>

              : null}
            {cartLeng === 6 ?
              <div>
                <div className="product-image6-1">
                  <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image6-1" />
                </div>
                <div className="product-image6-2">
                  <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image6-2" />
                </div>
                <div className="product-image6-3">
                  <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image6-3" />
                </div>
                <div className="product-image6-4">
                  <img src={cartUrunler_[3].imgURL} alt="Pattern product image" className="pattern-product-image6-4" />
                </div>
                <div className="product-image6-5">
                  <img src={cartUrunler_[4].imgURL} alt="Pattern product image" className="pattern-product-image6-5" />
                </div>
                <div className="product-image6-6">
                  <img src={cartUrunler_[5].imgURL} alt="Pattern product image" className="pattern-product-image6-6" />
                </div>
              </div>
              : null}
            {cartLeng === 9 ?
              <div>
                <div className="product-image9-1">
                  <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image9-1" />
                </div>
                <div className="product-image9-2">
                  <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image9-2" />
                </div>
                <div className="product-image9-3">
                  <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image9-3" />
                </div>
                <div className="product-image9-4">
                  <img src={cartUrunler_[3].imgURL} alt="Pattern product image" className="pattern-product-image9-4" />
                </div>
                <div className="product-image9-5">
                  <img src={cartUrunler_[4].imgURL} alt="Pattern product image" className="pattern-product-image9-5" />
                </div>
                <div className="product-image9-6">
                  <img src={cartUrunler_[5].imgURL} alt="Pattern product image" className="pattern-product-image9-6" />
                </div>
                <div className="product-image9-7">
                  <img src={cartUrunler_[6].imgURL} alt="Pattern product image" className="pattern-product-image9-7" />
                </div>
                <div className="product-image9-8">
                  <img src={cartUrunler_[7].imgURL} alt="Pattern product image" className="pattern-product-image9-8" />
                </div>
                <div className="product-image9-9">
                  <img src={cartUrunler_[8].imgURL} alt="Pattern product image" className="pattern-product-image9-9" />
                </div>
              </div>
              : null}
            <div className="address">{loginInfo && loginInfo[0].address}</div>
            <div className="phone">{loginInfo && loginInfo[0].phone}</div>
            <div className={classNames({
              "campaing-dates": true,
              "none": day === undefined || day_ === undefined,
            })}>
              {startDate_} - {endDate_}
            </div>

          </div>}
        </Col>
        <Col xl="4" lg="4" sm="12" md="12" className="pattern-right-sidebar">
          <Row>
            <Form>
              <Row>
                <FormGroup>
                  <Label for="exampleSelect">
                    {t('description.campaingTitle')}
                  </Label>
                  <Input placeholder={t('description.campaingTitle')} type='text' value={i_campTitle} onChange={(e) => setI_campTitle(e.target.value)} />
                </FormGroup>
              </Row>
              <Row>
                <Col xl="6">
                  <FormGroup>
                    <Label for="exampleSelect">
                      {t('description.startDate')}
                    </Label>
                    <Input
                      id="exampleDate"
                      name="date"
                      placeholder="date placeholder"
                      type="date"
                      value={i_startDate}
                      onChange={(e) => setI_startDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup>
                    <Label for="exampleSelect">
                      {t('description.endDate')}
                    </Label>
                    <Input
                      id="exampleDate"
                      name="date"
                      placeholder="date placeholder"
                      type="date"
                      value={i_endDate}
                      onChange={(e) => setI_endDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Row>
          <hr />
          <h1 className='select-pattern-title'>{t('description.selectPattern')}</h1>
          <Row className="pattern-list">
            {cartUrunler_.length === 1 ? <PatternItemTekli /> : null}
            {cartUrunler_.length === 3 ? <PatternItemUclu /> : null}
            {cartUrunler_.length === 6 ? <PatternItemAltili /> : null}
            {cartUrunler_.length === 9 ? <PatternItemDokuzlu /> : null}
          </Row>
          <hr />
          <Row className="save-button">
            <Col>
              <Button outline color="warning" onClick={navi}>{t('description.updateProduct')}</Button>
            </Col>
            <Col>
              <Button outline color="success" onClick={() => giveMeSS("capture")}>{t('description.saveAndDownload')}</Button>
            </Col>
          </Row>
        </Col>

      </Row>
      {/* Desktop Pattern Finish */}

      {/* Mobile Pattern Start */}
      <Row className="pattern-body hide-desktop" id="pattern-body">
        <Row>
          <Form>
            {/* Campaing Title Start */}
            <Row>
              <FormGroup>
                <Label for="exampleSelect">
                  {t('description.campaingTitle')}
                </Label>
                <Input placeholder={t('description.campaingTitle')} type='text' value={i_campTitle} onChange={(e) => setI_campTitle(e.target.value)} />
              </FormGroup>
            </Row>
            {/* Campaing Title Finish */}

            {/* Campaing Date Start */}
            <Row>
              <Col xl="6">
                <FormGroup>
                  <Label for="exampleSelect">
                    {t('description.startDate')}
                  </Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="date placeholder"
                    type="date"
                    value={i_startDate}
                    onChange={(e) => setI_startDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col xl="6">
                <FormGroup>
                  <Label for="exampleSelect">
                    {t('description.endDate')}
                  </Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="date placeholder"
                    type="date"
                    value={i_endDate}
                    onChange={(e) => setI_endDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* Campaing Date Finish */}
          </Form>
        </Row>
        {/* Canvas Start */}
        <Row>
          {pattern === undefined ? <Col xl="8" lg="8" sm="12" md="12" className="choose-pattern-mobile">{t('description.choosePattern')}</Col> :

            <Col xl="8" lg="8" sm="12" md="12" className="pattern-canvas">
              <div id='capture-mobile' className={classNames({
                'sablon_genel relative desktop img_sablon1-mobile img hide-desktop': true,
                'img_tekli_sablon1': pattern === 'ts1',
                'img_tekli_sablon2': pattern === 'ts2',
                'img_tekli_sablon3': pattern === 'ts3',
                'img_tekli_sablon4': pattern === 'ts4',
                'img_tekli_sablon5': pattern === 'ts5',
                'img_tekli_sablon6': pattern === 'ts6',
                'img_tekli_sablon7': pattern === 'ts7',
                'img_tekli_sablon8': pattern === 'ts8',
                'img_tekli_sablon9': pattern === 'ts9',
                'img_tekli_sablon10': pattern === 'ts10',
                'img_tekli_sablon11': pattern === 'ts11',
                'img_tekli_sablon12': pattern === 'ts12',
                'img_uclu_sablon1': pattern === 'us1',
                'img_uclu_sablon2': pattern === 'us2',
                'img_uclu_sablon3': pattern === 'us3',
                'img_uclu_sablon4': pattern === 'us4',
                'img_uclu_sablon5': pattern === 'us5',
                'img_uclu_sablon6': pattern === 'us6',
                'img_uclu_sablon7': pattern === 'us7',
                'img_uclu_sablon8': pattern === 'us8',
                'img_uclu_sablon9': pattern === 'us9',
                'img_uclu_sablon10': pattern === 'us10',
                'img_uclu_sablon11': pattern === 'us11',
                'img_uclu_sablon12': pattern === 'us12',
                'img_altili_sablon1': pattern === 'as1',
                'img_altili_sablon2': pattern === 'as2',
                'img_altili_sablon3': pattern === 'as3',
                'img_altili_sablon4': pattern === 'as4',
                'img_altili_sablon5': pattern === 'as5',
                'img_altili_sablon6': pattern === 'as6',
                'img_altili_sablon7': pattern === 'as7',
                'img_altili_sablon8': pattern === 'as8',
                'img_altili_sablon9': pattern === 'as9',
                'img_altili_sablon10': pattern === 'as10',
                'img_altili_sablon11': pattern === 'as11',
                'img_altili_sablon12': pattern === 'as12',
                'img_dokuzlu_sablon1': pattern === 'ds1',
                'img_dokuzlu_sablon2': pattern === 'ds2',
                'img_dokuzlu_sablon3': pattern === 'ds3',
                'img_dokuzlu_sablon4': pattern === 'ds4',
                'img_dokuzlu_sablon5': pattern === 'ds5',
                'img_dokuzlu_sablon6': pattern === 'ds6',
                'img_dokuzlu_sablon7': pattern === 'ds7',
                'img_dokuzlu_sablon8': pattern === 'ds8',
                'img_dokuzlu_sablon9': pattern === 'ds9',
              })}>
                {cartLeng === 1 ?
                  <div className={classNames({
                    'old-price': true,
                    'none': cartUrunler_[0].price === undefined
                  })} >
                    {cartUrunler_[0].price}€
                  </div> : null}
                {cartLeng === 3 ?
                  <div>
                    <div className={classNames({
                      'old-price3-1': true,
                      'none': cartUrunler_[0].price === undefined
                    })}>
                      {cartUrunler_[0].price}€
                    </div>
                    <div className={classNames({
                      'old-price3-2': true,
                      'none': cartUrunler_[1].price === undefined
                    })}>
                      {cartUrunler_[1].price}€
                    </div>
                    <div className={classNames({
                      'old-price3-3': true,
                      'none': cartUrunler_[2].price === undefined
                    })} >
                      {cartUrunler_[2].price}€
                    </div>
                  </div>
                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className={classNames({
                      'old-price6-1': true,
                      'none': cartUrunler_[0].price === undefined
                    })} >
                      {cartUrunler_[0].price}€
                    </div>
                    <div className={classNames({
                      'old-price6-2': true,
                      'none': cartUrunler_[1].price === undefined
                    })} >
                      {cartUrunler_[1].price}€
                    </div>
                    <div className={classNames({
                      'old-price6-3': true,
                      'none': cartUrunler_[2].price === undefined
                    })} >
                      {cartUrunler_[2].price}€
                    </div>
                    <div className={classNames({
                      'old-price6-4': true,
                      'none': cartUrunler_[3].price === undefined
                    })} >
                      {cartUrunler_[3].price}€
                    </div>
                    <div className={classNames({
                      'old-price6-5': true,
                      'none': cartUrunler_[4].price === undefined
                    })} >
                      {cartUrunler_[4].price}€
                    </div>
                    <div className={classNames({
                      'old-price6-6': true,
                      'none': cartUrunler_[5].price === undefined
                    })} >
                      {cartUrunler_[5].price}€
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className={classNames({
                      'old-price9-1': true,
                      'none': cartUrunler_[0].price === undefined
                    })} >
                      {cartUrunler_[0].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-2': true,
                      'none': cartUrunler_[1].price === undefined
                    })} >
                      {cartUrunler_[1].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-3': true,
                      'none': cartUrunler_[2].price === undefined
                    })} >
                      {cartUrunler_[2].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-4': true,
                      'none': cartUrunler_[3].price === undefined
                    })} >
                      {cartUrunler_[3].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-5': true,
                      'none': cartUrunler_[4].price === undefined
                    })} >
                      {cartUrunler_[4].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-6': true,
                      'none': cartUrunler_[5].price === undefined
                    })} >
                      {cartUrunler_[5].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-7': true,
                      'none': cartUrunler_[6].price === undefined
                    })} >
                      {cartUrunler_[6].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-8': true,
                      'none': cartUrunler_[7].price === undefined
                    })} >
                      {cartUrunler_[7].price}€
                    </div>
                    <div className={classNames({
                      'old-price9-9': true,
                      'none': cartUrunler_[8].price === undefined
                    })} >
                      {cartUrunler_[8].price}€
                    </div> </div> : null}
                {cartLeng === 1 ? <div className="new-price" >
                  {cartUrunler_[0].newPrice}€
                </div> : null}
                {cartLeng === 3 ?
                  <div>
                    <div className="new-price3-1" >
                      {cartUrunler_[0].newPrice}€
                    </div>
                    <div className="new-price3-2" >
                      {cartUrunler_[1].newPrice}€
                    </div>
                    <div className="new-price3-3" >
                      {cartUrunler_[2].newPrice}€
                    </div>
                  </div>
                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className="new-price6-1" >
                      {cartUrunler_[0].newPrice}€
                    </div>
                    <div className="new-price6-2" >
                      {cartUrunler_[1].newPrice}€
                    </div>
                    <div className="new-price6-3" >
                      {cartUrunler_[2].newPrice}€
                    </div>
                    <div className="new-price6-4" >
                      {cartUrunler_[3].newPrice}€
                    </div>
                    <div className="new-price6-5" >
                      {cartUrunler_[4].newPrice}€
                    </div>
                    <div className="new-price6-6" >
                      {cartUrunler_[5].newPrice}€
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className="new-price9-1" >
                      {cartUrunler_[0].newPrice}€
                    </div>
                    <div className="new-price9-2" >
                      {cartUrunler_[1].newPrice}€
                    </div>
                    <div className="new-price9-3" >
                      {cartUrunler_[2].newPrice}€
                    </div>
                    <div className="new-price9-4" >
                      {cartUrunler_[3].newPrice}€
                    </div>
                    <div className="new-price9-5" >
                      {cartUrunler_[4].newPrice}€
                    </div>
                    <div className="new-price9-6" >
                      {cartUrunler_[5].newPrice}€
                    </div>
                    <div className="new-price9-7" >
                      {cartUrunler_[6].newPrice}€
                    </div>
                    <div className="new-price9-8" >
                      {cartUrunler_[7].newPrice}€
                    </div>
                    <div className="new-price9-9" >
                      {cartUrunler_[8].newPrice}€
                    </div> </div> : null}
                {cartLeng === 1 ? <div className={classNames({
                  "title": true,
                  "fs-19": cartUrunler_[0].title.length > 16,
                })} >
                  {cartUrunler_[0].title}
                </div> : null}
                {cartLeng === 3 ?
                  <div>
                    <div className={classNames({
                      "title3-1": true,
                      "fs-16": cartUrunler_[0].title.length > 16
                    })}>
                      {cartUrunler_[0].title}
                    </div>
                    <div className={classNames({
                      "title3-2": true,
                      "fs-16": cartUrunler_[1].title.length > 16
                    })}>
                      {cartUrunler_[1].title}
                    </div>
                    <div className={classNames({
                      "title3-3": true,
                      "fs-16": cartUrunler_[2].title.length > 16
                    })}>
                      {cartUrunler_[2].title}
                    </div>
                  </div>
                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className={classNames({
                      "title6-1": true,
                      "fs-12": cartUrunler_[0].title.length > 16
                    })}>
                      {cartUrunler_[0].title}
                    </div>
                    <div className={classNames({
                      "title6-2": true,
                      "fs-12": cartUrunler_[1].title.length > 16
                    })}>
                      {cartUrunler_[1].title}
                    </div>
                    <div className={classNames({
                      "title6-3": true,
                      "fs-12": cartUrunler_[2].title.length > 16
                    })}>
                      {cartUrunler_[2].title}
                    </div>
                    <div className={classNames({
                      "title6-4": true,
                      "fs-12": cartUrunler_[3].title.length > 16
                    })}>
                      {cartUrunler_[3].title}
                    </div>
                    <div className={classNames({
                      "title6-5": true,
                      "fs-12": cartUrunler_[4].title.length > 16
                    })}>
                      {cartUrunler_[4].title}
                    </div>
                    <div className={classNames({
                      "title6-6": true,
                      "fs-12": cartUrunler_[5].title.length > 16
                    })}>
                      {cartUrunler_[5].title}
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className="title9-1">
                      {cartUrunler_[0].title}
                    </div>
                    <div className="title9-2">
                      {cartUrunler_[1].title}
                    </div>
                    <div className="title9-3">
                      {cartUrunler_[2].title}
                    </div>
                    <div className="title9-4">
                      {cartUrunler_[3].title}
                    </div>
                    <div className="title9-5">
                      {cartUrunler_[4].title}
                    </div>
                    <div className="title9-6">
                      {cartUrunler_[5].title}
                    </div>
                    <div className="title9-7">
                      {cartUrunler_[6].title}
                    </div>
                    <div className="title9-8">
                      {cartUrunler_[7].title}
                    </div>
                    <div className="title9-9">
                      {cartUrunler_[8].title}
                    </div>
                  </div>
                  : null}
                {cartLeng === 1 ? <div className={classNames({
                  "titleTR": true,
                  "fs-16": cartUrunler_[0].titleTR.length > 16,
                })}>
                  {cartUrunler_[0].titleTR}
                </div> : null}
                {cartLeng === 3 ?
                  <div>
                    <div className={classNames({
                      "titleTR3-1": true,
                      "fs-16": cartUrunler_[0].titleTR.length > 16,
                    })}>
                      {cartUrunler_[0].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR3-2": true,
                      "fs-16": cartUrunler_[1].titleTR.length > 16,
                    })}>
                      {cartUrunler_[1].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR3-3": true,
                      "fs-16": cartUrunler_[2].titleTR.length > 16,
                    })}>
                      {cartUrunler_[2].titleTR}
                    </div>
                  </div>
                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className={classNames({
                      "titleTR6-1": true,
                      "fs-10": cartUrunler_[0].titleTR.length > 16,
                    })}>
                      {cartUrunler_[0].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR6-2": true,
                      "fs-10": cartUrunler_[1].titleTR.length > 16,
                    })}>
                      {cartUrunler_[1].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR6-3": true,
                      "fs-10": cartUrunler_[2].titleTR.length > 16,
                    })}>
                      {cartUrunler_[2].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR6-4": true,
                      "fs-10": cartUrunler_[3].titleTR.length > 16,
                    })}>
                      {cartUrunler_[3].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR6-5": true,
                      "fs-10": cartUrunler_[4].titleTR.length > 16,
                    })}>
                      {cartUrunler_[4].titleTR}
                    </div>
                    <div className={classNames({
                      "titleTR6-6": true,
                      "fs-10": cartUrunler_[5].titleTR.length > 16,
                    })}>
                      {cartUrunler_[5].titleTR}
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className="titleTR9-1">
                      {cartUrunler_[0].titleTR}
                    </div>
                    <div className="titleTR9-2">
                      {cartUrunler_[1].titleTR}
                    </div>
                    <div className="titleTR9-3">
                      {cartUrunler_[2].titleTR}
                    </div>
                    <div className="titleTR9-4">
                      {cartUrunler_[3].titleTR}
                    </div>
                    <div className="titleTR9-5">
                      {cartUrunler_[4].titleTR}
                    </div>
                    <div className="titleTR9-6">
                      {cartUrunler_[5].titleTR}
                    </div>
                    <div className="titleTR9-7">
                      {cartUrunler_[6].titleTR}
                    </div>
                    <div className="titleTR9-8">
                      {cartUrunler_[7].titleTR}
                    </div>
                    <div className="titleTR9-9">
                      {cartUrunler_[8].titleTR}
                    </div>
                  </div>
                  : null}
                {cartLeng === 1 ? <div className={classNames({
                  'origin': true,
                  'none': cartUrunler_[0].origin === undefined
                })}>
                  {cartUrunler_[0].origin}
                </div> : null}
                {cartLeng === 3 ?
                  <div>
                    <div className={classNames({
                      'origin3-1': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[0].origin}
                    </div>
                    <div className={classNames({
                      'origin3-2': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[1].origin}
                    </div>
                    <div className={classNames({
                      'origin3-3': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[2].origin}
                    </div>
                  </div>
                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className={classNames({
                      'origin6-1': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[0].origin}
                    </div>
                    <div className={classNames({
                      'origin6-2': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[1].origin}
                    </div>
                    <div className={classNames({
                      'origin6-3': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[2].origin}
                    </div>
                    <div className={classNames({
                      'origin6-4': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[3].origin}
                    </div>
                    <div className={classNames({
                      'origin6-5': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[4].origin}
                    </div>
                    <div className={classNames({
                      'origin6-6': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[5].origin}
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className={classNames({
                      'origin9-1': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[0].origin}
                    </div>
                    <div className={classNames({
                      'origin9-2': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[1].origin}
                    </div>
                    <div className={classNames({
                      'origin9-3': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[2].origin}
                    </div>
                    <div className={classNames({
                      'origin9-4': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[3].origin}
                    </div>
                    <div className={classNames({
                      'origin9-5': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[4].origin}
                    </div>
                    <div className={classNames({
                      'origin9-6': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[5].origin}
                    </div>
                    <div className={classNames({
                      'origin9-7': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[6].origin}
                    </div>
                    <div className={classNames({
                      'origin9-8': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[7].origin}
                    </div>
                    <div className={classNames({
                      'origin9-9': true,
                      'none': cartUrunler_[0].origin === undefined
                    })}>
                      {cartUrunler_[8].origin}
                    </div>
                  </div>
                  : null}

                {cartLeng === 1 ? <div className="weight">
                  {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                  {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                </div> : null}
                {cartLeng === 3 ?
                  <div>
                    <div className="weight3-1">
                      {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                      {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight3-2">
                      {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                      {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null}   {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight3-3">
                      {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                      {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}   {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                    </div>
                  </div>
                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className="weight6-1">
                      {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                      {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight6-2">
                      {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                      {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight6-3">
                      {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                      {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight6-4">
                      {calculatePrice(cartUrunler_[3].weight, cartUrunler_[3].unit, cartUrunler_[3].newPrice)}
                      {cartUrunler_[3].pcs ? <>({cartUrunler_[3].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[3].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight6-5">
                      {calculatePrice(cartUrunler_[4].weight, cartUrunler_[4].unit, cartUrunler_[4].newPrice)}
                      {cartUrunler_[4].pcs ? <>({cartUrunler_[4].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[4].weight} {cartUrunler_[4].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight6-6">
                      {calculatePrice(cartUrunler_[5].weight, cartUrunler_[5].unit, cartUrunler_[5].newPrice)}
                      {cartUrunler_[5].pcs ? <>({cartUrunler_[5].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[5].weight} {cartUrunler_[5].unit} | 1 {unitkg} = {a}€
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className="weight9-1">
                      {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                      {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-2">
                      {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                      {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-3">
                      {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                      {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-4">
                      {calculatePrice(cartUrunler_[3].weight, cartUrunler_[3].unit, cartUrunler_[3].newPrice)}
                      {cartUrunler_[3].pcs ? <>({cartUrunler_[3].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[3].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-5">
                      {calculatePrice(cartUrunler_[4].weight, cartUrunler_[4].unit, cartUrunler_[4].newPrice)}
                      {cartUrunler_[4].pcs ? <>({cartUrunler_[4].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[4].weight} {cartUrunler_[4].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-6">
                      {calculatePrice(cartUrunler_[5].weight, cartUrunler_[5].unit, cartUrunler_[5].newPrice)}
                      {cartUrunler_[5].pcs ? <>({cartUrunler_[5].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[5].weight} {cartUrunler_[5].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-7">
                      {calculatePrice(cartUrunler_[6].weight, cartUrunler_[6].unit, cartUrunler_[6].newPrice)}
                      {cartUrunler_[6].pcs ? <>({cartUrunler_[6].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[6].weight} {cartUrunler_[6].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-8">
                      {calculatePrice(cartUrunler_[7].weight, cartUrunler_[7].unit, cartUrunler_[7].newPrice)}
                      {cartUrunler_[7].pcs ? <>({cartUrunler_[7].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[7].weight} {cartUrunler_[7].unit} | 1 {unitkg} = {a}€
                    </div>
                    <div className="weight9-9">
                      {calculatePrice(cartUrunler_[8].weight, cartUrunler_[8].unit, cartUrunler_[8].newPrice)}
                      {cartUrunler_[8].pcs ? <>({cartUrunler_[8].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[8].weight} {cartUrunler_[8].unit} | 1 {unitkg} = {a}€
                    </div>
                  </div>
                  : null}
                <div className="pattern-logo">
                  <img src={loginInfo && loginInfo[0].logoURL === "" ? Logo : loginInfo[0].logoURL} alt="Logo" width="150px" />
                </div>
                {cartLeng === 1 ?
                  <div className="product-image">
                    <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image" />
                  </div>
                  : null}
                {cartLeng === 3 ?
                  <div>
                    <div className="product-image3-1">
                      <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image3-1" />
                    </div>
                    <div className="product-image3-2">
                      <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image3-2" />
                    </div>
                    <div className="product-image3-3">
                      <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image3-3" />
                    </div>
                  </div>

                  : null}
                {cartLeng === 6 ?
                  <div>
                    <div className="product-image6-1">
                      <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image6-1" />
                    </div>
                    <div className="product-image6-2">
                      <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image6-2" />
                    </div>
                    <div className="product-image6-3">
                      <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image6-3" />
                    </div>
                    <div className="product-image6-4">
                      <img src={cartUrunler_[3].imgURL} alt="Pattern product image" className="pattern-product-image6-4" />
                    </div>
                    <div className="product-image6-5">
                      <img src={cartUrunler_[4].imgURL} alt="Pattern product image" className="pattern-product-image6-5" />
                    </div>
                    <div className="product-image6-6">
                      <img src={cartUrunler_[5].imgURL} alt="Pattern product image" className="pattern-product-image6-6" />
                    </div>
                  </div>
                  : null}
                {cartLeng === 9 ?
                  <div>
                    <div className="product-image9-1">
                      <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image9-1" />
                    </div>
                    <div className="product-image9-2">
                      <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image9-2" />
                    </div>
                    <div className="product-image9-3">
                      <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image9-3" />
                    </div>
                    <div className="product-image9-4">
                      <img src={cartUrunler_[3].imgURL} alt="Pattern product image" className="pattern-product-image9-4" />
                    </div>
                    <div className="product-image9-5">
                      <img src={cartUrunler_[4].imgURL} alt="Pattern product image" className="pattern-product-image9-5" />
                    </div>
                    <div className="product-image9-6">
                      <img src={cartUrunler_[5].imgURL} alt="Pattern product image" className="pattern-product-image9-6" />
                    </div>
                    <div className="product-image9-7">
                      <img src={cartUrunler_[6].imgURL} alt="Pattern product image" className="pattern-product-image9-7" />
                    </div>
                    <div className="product-image9-8">
                      <img src={cartUrunler_[7].imgURL} alt="Pattern product image" className="pattern-product-image9-8" />
                    </div>
                    <div className="product-image9-9">
                      <img src={cartUrunler_[8].imgURL} alt="Pattern product image" className="pattern-product-image9-9" />
                    </div>
                  </div>
                  : null}
                <div className="address">{loginInfo && loginInfo[0].address}</div>
                <div className="phone">{loginInfo && loginInfo[0].phone}</div>
                <div className={classNames({
                  "campaing-dates": true,
                  "none": day === undefined || day_ === undefined,
                })}>
                  {startDate_} - {endDate_}
                </div>
              </div>
            </Col>
          }

          <Col xl="8" lg="8" sm="12" md="12" className="pattern-canvas">
            {pattern === undefined ? <div>{t('description.choosePattern')}</div> : <div id='capture-1' className={classNames({
              'sablon_genel relative desktop img_sablon2-mobile img hide-desktop': true,
              'img_tekli_sablon1': pattern === 'ts1',
              'img_tekli_sablon2': pattern === 'ts2',
              'img_tekli_sablon3': pattern === 'ts3',
              'img_tekli_sablon4': pattern === 'ts4',
              'img_tekli_sablon5': pattern === 'ts5',
              'img_tekli_sablon6': pattern === 'ts6',
              'img_tekli_sablon7': pattern === 'ts7',
              'img_tekli_sablon8': pattern === 'ts8',
              'img_tekli_sablon9': pattern === 'ts9',
              'img_tekli_sablon10': pattern === 'ts10',
              'img_tekli_sablon11': pattern === 'ts11',
              'img_tekli_sablon12': pattern === 'ts12',
              'img_uclu_sablon1': pattern === 'us1',
              'img_uclu_sablon2': pattern === 'us2',
              'img_uclu_sablon3': pattern === 'us3',
              'img_uclu_sablon4': pattern === 'us4',
              'img_uclu_sablon5': pattern === 'us5',
              'img_uclu_sablon6': pattern === 'us6',
              'img_uclu_sablon7': pattern === 'us7',
              'img_uclu_sablon8': pattern === 'us8',
              'img_uclu_sablon9': pattern === 'us9',
              'img_uclu_sablon10': pattern === 'us10',
              'img_uclu_sablon11': pattern === 'us11',
              'img_uclu_sablon12': pattern === 'us12',
              'img_altili_sablon1': pattern === 'as1',
              'img_altili_sablon2': pattern === 'as2',
              'img_altili_sablon3': pattern === 'as3',
              'img_altili_sablon4': pattern === 'as4',
              'img_altili_sablon5': pattern === 'as5',
              'img_altili_sablon6': pattern === 'as6',
              'img_altili_sablon7': pattern === 'as7',
              'img_altili_sablon8': pattern === 'as8',
              'img_altili_sablon9': pattern === 'as9',
              'img_altili_sablon10': pattern === 'as10',
              'img_altili_sablon11': pattern === 'as11',
              'img_altili_sablon12': pattern === 'as12',
              'img_dokuzlu_sablon1': pattern === 'ds1',
              'img_dokuzlu_sablon2': pattern === 'ds2',
              'img_dokuzlu_sablon3': pattern === 'ds3',
              'img_dokuzlu_sablon4': pattern === 'ds4',
              'img_dokuzlu_sablon5': pattern === 'ds5',
              'img_dokuzlu_sablon6': pattern === 'ds6',
              'img_dokuzlu_sablon7': pattern === 'ds7',
              'img_dokuzlu_sablon8': pattern === 'ds8',
              'img_dokuzlu_sablon9': pattern === 'ds9',
            })}>
              {cartLeng === 1 ?
                <div className={classNames({
                  'old-price': true,
                  'none': cartUrunler_[0].price === undefined
                })} >
                  {cartUrunler_[0].price}€
                </div> : null}
              {cartLeng === 3 ?
                <div>
                  <div className={classNames({
                    'old-price3-1': true,
                    'none': cartUrunler_[0].price === undefined
                  })}>
                    {cartUrunler_[0].price}€
                  </div>
                  <div className={classNames({
                    'old-price3-2': true,
                    'none': cartUrunler_[1].price === undefined
                  })}>
                    {cartUrunler_[1].price}€
                  </div>
                  <div className={classNames({
                    'old-price3-3': true,
                    'none': cartUrunler_[2].price === undefined
                  })} >
                    {cartUrunler_[2].price}€
                  </div>
                </div>
                : null}
              {cartLeng === 6 ?
                <div>
                  <div className={classNames({
                    'old-price6-1': true,
                    'none': cartUrunler_[0].price === undefined
                  })} >
                    {cartUrunler_[0].price}€
                  </div>
                  <div className={classNames({
                    'old-price6-2': true,
                    'none': cartUrunler_[1].price === undefined
                  })} >
                    {cartUrunler_[1].price}€
                  </div>
                  <div className={classNames({
                    'old-price6-3': true,
                    'none': cartUrunler_[2].price === undefined
                  })} >
                    {cartUrunler_[2].price}€
                  </div>
                  <div className={classNames({
                    'old-price6-4': true,
                    'none': cartUrunler_[3].price === undefined
                  })} >
                    {cartUrunler_[3].price}€
                  </div>
                  <div className={classNames({
                    'old-price6-5': true,
                    'none': cartUrunler_[4].price === undefined
                  })} >
                    {cartUrunler_[4].price}€
                  </div>
                  <div className={classNames({
                    'old-price6-6': true,
                    'none': cartUrunler_[5].price === undefined
                  })} >
                    {cartUrunler_[5].price}€
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className={classNames({
                    'old-price9-1': true,
                    'none': cartUrunler_[0].price === undefined
                  })} >
                    {cartUrunler_[0].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-2': true,
                    'none': cartUrunler_[1].price === undefined
                  })} >
                    {cartUrunler_[1].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-3': true,
                    'none': cartUrunler_[2].price === undefined
                  })} >
                    {cartUrunler_[2].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-4': true,
                    'none': cartUrunler_[3].price === undefined
                  })} >
                    {cartUrunler_[3].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-5': true,
                    'none': cartUrunler_[4].price === undefined
                  })} >
                    {cartUrunler_[4].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-6': true,
                    'none': cartUrunler_[5].price === undefined
                  })} >
                    {cartUrunler_[5].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-7': true,
                    'none': cartUrunler_[6].price === undefined
                  })} >
                    {cartUrunler_[6].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-8': true,
                    'none': cartUrunler_[7].price === undefined
                  })} >
                    {cartUrunler_[7].price}€
                  </div>
                  <div className={classNames({
                    'old-price9-9': true,
                    'none': cartUrunler_[8].price === undefined
                  })} >
                    {cartUrunler_[8].price}€
                  </div> </div> : null}
              {cartLeng === 1 ? <div className="new-price" >
                {cartUrunler_[0].newPrice}€
              </div> : null}
              {cartLeng === 3 ?
                <div>
                  <div className="new-price3-1" >
                    {cartUrunler_[0].newPrice}€
                  </div>
                  <div className="new-price3-2" >
                    {cartUrunler_[1].newPrice}€
                  </div>
                  <div className="new-price3-3" >
                    {cartUrunler_[2].newPrice}€
                  </div>
                </div>
                : null}
              {cartLeng === 6 ?
                <div>
                  <div className="new-price6-1" >
                    {cartUrunler_[0].newPrice}€
                  </div>
                  <div className="new-price6-2" >
                    {cartUrunler_[1].newPrice}€
                  </div>
                  <div className="new-price6-3" >
                    {cartUrunler_[2].newPrice}€
                  </div>
                  <div className="new-price6-4" >
                    {cartUrunler_[3].newPrice}€
                  </div>
                  <div className="new-price6-5" >
                    {cartUrunler_[4].newPrice}€
                  </div>
                  <div className="new-price6-6" >
                    {cartUrunler_[5].newPrice}€
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className="new-price9-1" >
                    {cartUrunler_[0].newPrice}€
                  </div>
                  <div className="new-price9-2" >
                    {cartUrunler_[1].newPrice}€
                  </div>
                  <div className="new-price9-3" >
                    {cartUrunler_[2].newPrice}€
                  </div>
                  <div className="new-price9-4" >
                    {cartUrunler_[3].newPrice}€
                  </div>
                  <div className="new-price9-5" >
                    {cartUrunler_[4].newPrice}€
                  </div>
                  <div className="new-price9-6" >
                    {cartUrunler_[5].newPrice}€
                  </div>
                  <div className="new-price9-7" >
                    {cartUrunler_[6].newPrice}€
                  </div>
                  <div className="new-price9-8" >
                    {cartUrunler_[7].newPrice}€
                  </div>
                  <div className="new-price9-9" >
                    {cartUrunler_[8].newPrice}€
                  </div> </div> : null}
              {cartLeng === 1 ? <div className={classNames({
                "title": true,
                "fs-19": cartUrunler_[0].title.length > 16,
              })} >
                {cartUrunler_[0].title}
              </div> : null}
              {cartLeng === 3 ?
                <div>
                  <div className={classNames({
                    "title3-1": true,
                    "fs-16": cartUrunler_[0].title.length > 16
                  })}>
                    {cartUrunler_[0].title}
                  </div>
                  <div className={classNames({
                    "title3-2": true,
                    "fs-16": cartUrunler_[1].title.length > 16
                  })}>
                    {cartUrunler_[1].title}
                  </div>
                  <div className={classNames({
                    "title3-3": true,
                    "fs-16": cartUrunler_[2].title.length > 16
                  })}>
                    {cartUrunler_[2].title}
                  </div>
                </div>
                : null}
              {cartLeng === 6 ?
                <div>
                  <div className={classNames({
                    "title6-1": true,
                    "fs-12": cartUrunler_[0].title.length > 16
                  })}>
                    {cartUrunler_[0].title}
                  </div>
                  <div className={classNames({
                    "title6-2": true,
                    "fs-12": cartUrunler_[1].title.length > 16
                  })}>
                    {cartUrunler_[1].title}
                  </div>
                  <div className={classNames({
                    "title6-3": true,
                    "fs-12": cartUrunler_[2].title.length > 16
                  })}>
                    {cartUrunler_[2].title}
                  </div>
                  <div className={classNames({
                    "title6-4": true,
                    "fs-12": cartUrunler_[3].title.length > 16
                  })}>
                    {cartUrunler_[3].title}
                  </div>
                  <div className={classNames({
                    "title6-5": true,
                    "fs-12": cartUrunler_[4].title.length > 16
                  })}>
                    {cartUrunler_[4].title}
                  </div>
                  <div className={classNames({
                    "title6-6": true,
                    "fs-12": cartUrunler_[5].title.length > 16
                  })}>
                    {cartUrunler_[5].title}
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className="title9-1">
                    {cartUrunler_[0].title}
                  </div>
                  <div className="title9-2">
                    {cartUrunler_[1].title}
                  </div>
                  <div className="title9-3">
                    {cartUrunler_[2].title}
                  </div>
                  <div className="title9-4">
                    {cartUrunler_[3].title}
                  </div>
                  <div className="title9-5">
                    {cartUrunler_[4].title}
                  </div>
                  <div className="title9-6">
                    {cartUrunler_[5].title}
                  </div>
                  <div className="title9-7">
                    {cartUrunler_[6].title}
                  </div>
                  <div className="title9-8">
                    {cartUrunler_[7].title}
                  </div>
                  <div className="title9-9">
                    {cartUrunler_[8].title}
                  </div>
                </div>
                : null}
              {cartLeng === 1 ? <div className={classNames({
                "titleTR": true,
                "fs-16": cartUrunler_[0].titleTR.length > 16,
              })}>
                {cartUrunler_[0].titleTR}
              </div> : null}
              {cartLeng === 3 ?
                <div>
                  <div className={classNames({
                    "titleTR3-1": true,
                    "fs-16": cartUrunler_[0].titleTR.length > 16,
                  })}>
                    {cartUrunler_[0].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR3-2": true,
                    "fs-16": cartUrunler_[1].titleTR.length > 16,
                  })}>
                    {cartUrunler_[1].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR3-3": true,
                    "fs-16": cartUrunler_[2].titleTR.length > 16,
                  })}>
                    {cartUrunler_[2].titleTR}
                  </div>
                </div>
                : null}
              {cartLeng === 6 ?
                <div>
                  <div className={classNames({
                    "titleTR6-1": true,
                    "fs-10": cartUrunler_[0].titleTR.length > 16,
                  })}>
                    {cartUrunler_[0].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR6-2": true,
                    "fs-10": cartUrunler_[1].titleTR.length > 16,
                  })}>
                    {cartUrunler_[1].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR6-3": true,
                    "fs-10": cartUrunler_[2].titleTR.length > 16,
                  })}>
                    {cartUrunler_[2].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR6-4": true,
                    "fs-10": cartUrunler_[3].titleTR.length > 16,
                  })}>
                    {cartUrunler_[3].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR6-5": true,
                    "fs-10": cartUrunler_[4].titleTR.length > 16,
                  })}>
                    {cartUrunler_[4].titleTR}
                  </div>
                  <div className={classNames({
                    "titleTR6-6": true,
                    "fs-10": cartUrunler_[5].titleTR.length > 16,
                  })}>
                    {cartUrunler_[5].titleTR}
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className="titleTR9-1">
                    {cartUrunler_[0].titleTR}
                  </div>
                  <div className="titleTR9-2">
                    {cartUrunler_[1].titleTR}
                  </div>
                  <div className="titleTR9-3">
                    {cartUrunler_[2].titleTR}
                  </div>
                  <div className="titleTR9-4">
                    {cartUrunler_[3].titleTR}
                  </div>
                  <div className="titleTR9-5">
                    {cartUrunler_[4].titleTR}
                  </div>
                  <div className="titleTR9-6">
                    {cartUrunler_[5].titleTR}
                  </div>
                  <div className="titleTR9-7">
                    {cartUrunler_[6].titleTR}
                  </div>
                  <div className="titleTR9-8">
                    {cartUrunler_[7].titleTR}
                  </div>
                  <div className="titleTR9-9">
                    {cartUrunler_[8].titleTR}
                  </div>
                </div>
                : null}
              {cartLeng === 1 ? <div className={classNames({
                'origin': true,
                'none': cartUrunler_[0].origin === undefined
              })}>
                {cartUrunler_[0].origin}
              </div> : null}
              {cartLeng === 3 ?
                <div>
                  <div className={classNames({
                    'origin3-1': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[0].origin}
                  </div>
                  <div className={classNames({
                    'origin3-2': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[1].origin}
                  </div>
                  <div className={classNames({
                    'origin3-3': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[2].origin}
                  </div>
                </div>
                : null}
              {cartLeng === 6 ?
                <div>
                  <div className={classNames({
                    'origin6-1': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[0].origin}
                  </div>
                  <div className={classNames({
                    'origin6-2': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[1].origin}
                  </div>
                  <div className={classNames({
                    'origin6-3': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[2].origin}
                  </div>
                  <div className={classNames({
                    'origin6-4': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[3].origin}
                  </div>
                  <div className={classNames({
                    'origin6-5': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[4].origin}
                  </div>
                  <div className={classNames({
                    'origin6-6': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[5].origin}
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className={classNames({
                    'origin9-1': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[0].origin}
                  </div>
                  <div className={classNames({
                    'origin9-2': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[1].origin}
                  </div>
                  <div className={classNames({
                    'origin9-3': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[2].origin}
                  </div>
                  <div className={classNames({
                    'origin9-4': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[3].origin}
                  </div>
                  <div className={classNames({
                    'origin9-5': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[4].origin}
                  </div>
                  <div className={classNames({
                    'origin9-6': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[5].origin}
                  </div>
                  <div className={classNames({
                    'origin9-7': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[6].origin}
                  </div>
                  <div className={classNames({
                    'origin9-8': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[7].origin}
                  </div>
                  <div className={classNames({
                    'origin9-9': true,
                    'none': cartUrunler_[0].origin === undefined
                  })}>
                    {cartUrunler_[8].origin}
                  </div>
                </div>
                : null}

              {cartLeng === 1 ? <div className="weight">
                {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
              </div> : null}
              {cartLeng === 3 ?
                <div>
                  <div className="weight3-1">
                    {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                    {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight3-2">
                    {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                    {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null}   {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight3-3">
                    {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                    {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}   {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                  </div>
                </div>
                : null}
              {cartLeng === 6 ?
                <div>
                  <div className="weight6-1">
                    {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                    {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight6-2">
                    {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                    {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight6-3">
                    {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                    {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight6-4">
                    {calculatePrice(cartUrunler_[3].weight, cartUrunler_[3].unit, cartUrunler_[3].newPrice)}
                    {cartUrunler_[3].pcs ? <>({cartUrunler_[3].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[3].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight6-5">
                    {calculatePrice(cartUrunler_[4].weight, cartUrunler_[4].unit, cartUrunler_[4].newPrice)}
                    {cartUrunler_[4].pcs ? <>({cartUrunler_[4].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[4].weight} {cartUrunler_[4].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight6-6">
                    {calculatePrice(cartUrunler_[5].weight, cartUrunler_[5].unit, cartUrunler_[5].newPrice)}
                    {cartUrunler_[5].pcs ? <>({cartUrunler_[5].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[5].weight} {cartUrunler_[5].unit} | 1 {unitkg} = {a}€
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className="weight9-1">
                    {calculatePrice(cartUrunler_[0].weight, cartUrunler_[0].unit, cartUrunler_[0].newPrice)}
                    {cartUrunler_[0].pcs ? <>({cartUrunler_[0].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[0].weight} {cartUrunler_[0].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-2">
                    {calculatePrice(cartUrunler_[1].weight, cartUrunler_[1].unit, cartUrunler_[1].newPrice)}
                    {cartUrunler_[1].pcs ? <>({cartUrunler_[1].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[1].weight} {cartUrunler_[1].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-3">
                    {calculatePrice(cartUrunler_[2].weight, cartUrunler_[2].unit, cartUrunler_[2].newPrice)}
                    {cartUrunler_[2].pcs ? <>({cartUrunler_[2].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[2].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-4">
                    {calculatePrice(cartUrunler_[3].weight, cartUrunler_[3].unit, cartUrunler_[3].newPrice)}
                    {cartUrunler_[3].pcs ? <>({cartUrunler_[3].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[3].weight} {cartUrunler_[2].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-5">
                    {calculatePrice(cartUrunler_[4].weight, cartUrunler_[4].unit, cartUrunler_[4].newPrice)}
                    {cartUrunler_[4].pcs ? <>({cartUrunler_[4].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[4].weight} {cartUrunler_[4].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-6">
                    {calculatePrice(cartUrunler_[5].weight, cartUrunler_[5].unit, cartUrunler_[5].newPrice)}
                    {cartUrunler_[5].pcs ? <>({cartUrunler_[5].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[5].weight} {cartUrunler_[5].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-7">
                    {calculatePrice(cartUrunler_[6].weight, cartUrunler_[6].unit, cartUrunler_[6].newPrice)}
                    {cartUrunler_[6].pcs ? <>({cartUrunler_[6].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[6].weight} {cartUrunler_[6].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-8">
                    {calculatePrice(cartUrunler_[7].weight, cartUrunler_[7].unit, cartUrunler_[7].newPrice)}
                    {cartUrunler_[7].pcs ? <>({cartUrunler_[7].pcs}  {t('description.pcs')})</> : null}  {cartUrunler_[7].weight} {cartUrunler_[7].unit} | 1 {unitkg} = {a}€
                  </div>
                  <div className="weight9-9">
                    {calculatePrice(cartUrunler_[8].weight, cartUrunler_[8].unit, cartUrunler_[8].newPrice)}
                    {cartUrunler_[8].pcs ? <>({cartUrunler_[8].pcs}  {t('description.pcs')})</> : null} {cartUrunler_[8].weight} {cartUrunler_[8].unit} | 1 {unitkg} = {a}€
                  </div>
                </div>
                : null}
              <div className="pattern-logo">
                <img src={loginInfo && loginInfo[0].logoURL === "" ? Logo : loginInfo[0].logoURL} alt="Logo" width="150px" />
              </div>
              {cartLeng === 1 ?
                <div className="product-image">
                  <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image" />
                </div>
                : null}
              {cartLeng === 3 ?
                <div>
                  <div className="product-image3-1">
                    <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image3-1" />
                  </div>
                  <div className="product-image3-2">
                    <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image3-2" />
                  </div>
                  <div className="product-image3-3">
                    <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image3-3" />
                  </div>
                </div>

                : null}
              {cartLeng === 6 ?
                <div>
                  <div className="product-image6-1">
                    <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image6-1" />
                  </div>
                  <div className="product-image6-2">
                    <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image6-2" />
                  </div>
                  <div className="product-image6-3">
                    <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image6-3" />
                  </div>
                  <div className="product-image6-4">
                    <img src={cartUrunler_[3].imgURL} alt="Pattern product image" className="pattern-product-image6-4" />
                  </div>
                  <div className="product-image6-5">
                    <img src={cartUrunler_[4].imgURL} alt="Pattern product image" className="pattern-product-image6-5" />
                  </div>
                  <div className="product-image6-6">
                    <img src={cartUrunler_[5].imgURL} alt="Pattern product image" className="pattern-product-image6-6" />
                  </div>
                </div>
                : null}
              {cartLeng === 9 ?
                <div>
                  <div className="product-image9-1">
                    <img src={cartUrunler_[0].imgURL} alt="Pattern product image" className="pattern-product-image9-1" />
                  </div>
                  <div className="product-image9-2">
                    <img src={cartUrunler_[1].imgURL} alt="Pattern product image" className="pattern-product-image9-2" />
                  </div>
                  <div className="product-image9-3">
                    <img src={cartUrunler_[2].imgURL} alt="Pattern product image" className="pattern-product-image9-3" />
                  </div>
                  <div className="product-image9-4">
                    <img src={cartUrunler_[3].imgURL} alt="Pattern product image" className="pattern-product-image9-4" />
                  </div>
                  <div className="product-image9-5">
                    <img src={cartUrunler_[4].imgURL} alt="Pattern product image" className="pattern-product-image9-5" />
                  </div>
                  <div className="product-image9-6">
                    <img src={cartUrunler_[5].imgURL} alt="Pattern product image" className="pattern-product-image9-6" />
                  </div>
                  <div className="product-image9-7">
                    <img src={cartUrunler_[6].imgURL} alt="Pattern product image" className="pattern-product-image9-7" />
                  </div>
                  <div className="product-image9-8">
                    <img src={cartUrunler_[7].imgURL} alt="Pattern product image" className="pattern-product-image9-8" />
                  </div>
                  <div className="product-image9-9">
                    <img src={cartUrunler_[8].imgURL} alt="Pattern product image" className="pattern-product-image9-9" />
                  </div>
                </div>
                : null}
              <div className="address">{loginInfo && loginInfo[0].address}</div>
              <div className="phone">{loginInfo && loginInfo[0].phone}</div>
              <div className={classNames({
                "campaing-dates": true,
                "none": day === undefined || day_ === undefined,
              })}>
                {startDate_} - {endDate_}
              </div>
            </div>}
          </Col>
        </Row>
        {/* Canvas Finish */}
        <Row>
          <h1 className='select-pattern-title'>{t('description.selectPattern')}</h1>
        </Row>
        <Row className="pattern-list">
          {cartUrunler_.length === 1 ? <PatternItemTekli /> : null}
          {cartUrunler_.length === 3 ? <PatternItemUclu /> : null}
          {cartUrunler_.length === 6 ? <PatternItemAltili /> : null}
          {cartUrunler_.length === 9 ? <PatternItemDokuzlu /> : null}
        </Row>
        <Row className="save-button">
          <Col>
            <Button outline color="warning" onClick={navi}>{t('description.updateProduct')}</Button>
          </Col>
          <Col>
            <Button outline color="success" onClick={() => giveMeSS("capture-1")}>{t('description.saveAndDownload')}</Button>
          </Col>
        </Row>
      </Row>
      {/* Mobile Pattern Finish */}


    </>
  )
}

export default Pattern