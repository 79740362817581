import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './style/md-screen.css';
import './style/sm-screen.css';
import './style/lg-screen.css';
import './style/style.css';
import './style/pattern-import.css';
import './style/tekli/img_tekli_sablon1.css'
import './style/tekli/img_tekli_sablon2.css'
import './style/tekli/img_tekli_sablon3.css'
import './style/tekli/img_tekli_sablon4.css'
import './style/tekli/img_tekli_sablon5.css'
import './style/tekli/img_tekli_sablon6.css'
import './style/tekli/img_tekli_sablon7.css'
import './style/tekli/img_tekli_sablon8.css'
import './style/tekli/img_tekli_sablon9.css'
import './style/tekli/img_tekli_sablon10.css'
import './style/tekli/img_tekli_sablon11.css'
import './style/tekli/img_tekli_sablon12.css'
import './style/uclu/img_uclu_sablon1.css'
import './style/uclu/img_uclu_sablon2.css'
import './style/uclu/img_uclu_sablon3.css'
import './style/uclu/img_uclu_sablon4.css'
import './style/uclu/img_uclu_sablon5.css'
import './style/uclu/img_uclu_sablon6.css'
import './style/uclu/img_uclu_sablon7.css'
import './style/uclu/img_uclu_sablon8.css'
import './style/uclu/img_uclu_sablon9.css'
import './style/uclu/img_uclu_sablon10.css'
import './style/uclu/img_uclu_sablon11.css'
import './style/uclu/img_uclu_sablon12.css'
import './style/altili/img_altili_sablon1.css'
import './style/altili/img_altili_sablon2.css'
import './style/altili/img_altili_sablon3.css'
import './style/altili/img_altili_sablon4.css'
import './style/altili/img_altili_sablon5.css'
import './style/altili/img_altili_sablon6.css'
import './style/altili/img_altili_sablon7.css'
import './style/altili/img_altili_sablon8.css'
import './style/altili/img_altili_sablon9.css'
import './style/altili/img_altili_sablon10.css'
import './style/altili/img_altili_sablon11.css'
import './style/altili/img_altili_sablon12.css'
import './style/dokuzlu/img_dokuzlu_sablon1.css'
import './style/dokuzlu/img_dokuzlu_sablon2.css'
import './style/dokuzlu/img_dokuzlu_sablon3.css'
import './style/dokuzlu/img_dokuzlu_sablon4.css'
import './style/dokuzlu/img_dokuzlu_sablon5.css'
import './style/dokuzlu/img_dokuzlu_sablon6.css'
import './style/dokuzlu/img_dokuzlu_sablon7.css'
import './style/dokuzlu/img_dokuzlu_sablon8.css'
import './style/dokuzlu/img_dokuzlu_sablon9.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home'
import Navbar from './components/Navbar'
import ProductList from './components/ProductList';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import MobileHeader from './components/MobileHeader';
import Pattern from './components/Pattern'
import Login from './components/Login';
import Profile from './components/Profile';
import { useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import SiteProvider, { useSiteContext } from './context/SiteContext';
import NewProduct from './components/NewProduct';
import NewUser from './components/NewUser';
import UserList from './components/UserList';

// release 1.0.0 30.09.2022

function App() {

  const { user } = useSelector(state => state.auth)

  const { loginInfo, newdate } = useSiteContext()

  const StyledApp = styled.div`
    .hide-mobile{
      @media(max-width:992px){
        display:none !important;
      }
    }
    .hide-desktop{
      @media(min-width:992px){
        display:none !important;
      }
    }
    .container {
      padding-top:2%;
      @media(max-width:992px){
        padding-top:20% !important
      }
      @media(max-width:768px){
        padding-top:35% !important
      }
    }
    .cart-list {
      @media(max-width:992px){
        padding-left:0.5em !important;
        padding-right:0.5em !important;
      }
    }
  `;






  return (
    <StyledApp className="h-100">
      <SiteProvider>
        <Toaster position="top-right" />

        {user ? <Row className="h-100">
          <Col xl="2" lg="3" sm="12" md="12"><Navbar /><MobileHeader /></Col>
          <Col xl="10" lg="9" sm="12" md="12" className="container px-0">
            <Routes>
              <Route index element={<Home />}></Route>
              <Route path="/product" element={<ProductList />}></Route>
              <Route path="/newproduct" element={<NewProduct />}></Route>
              <Route path="/userlist" element={<UserList />}></Route>
              <Route path="/newuser" element={<NewUser />}></Route>
              <Route path="/pattern" element={<Pattern />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
            </Routes>
          </Col>
        </Row> : <Login />}

      </SiteProvider>
    </StyledApp>
  );
}

export default App;